import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetStudentsFetchData, GetCohortsFetchData } from '../../redux/ManageStudents/actions';
import {
    BakeOpenBadges, GenerateCertificate, PreviewCertificate, IssueCertificate, IssueConfirm, RevokeCertificate, SendCertificate, SendCertificateforReview, ValidateAction,
    BatchCertificate, ResetCertificateAction, UnholdStudents, PreviewStudentPhoto, DownloadPreviewImage
} from '../../redux/ManageCertificate/actions';
import "./style.css";
import "../MyProfileView/custom.css";
import {
    getCohorts, batchprocess, bakeopenbadgesurl, getProfile, generatecertificate, previewcertificate, issuecertificate, issueconfirm, getStudentsForStep,
    revokeCertificate, sendCertificate, updatevalidate, resetcertificateURL, unholdStudents, previewstudentphoto, downloadPreviewImage, sendCertificateforReviewurl
} from "../../utils/constants";
import { issueBalance } from "../../utils/api";
import Spinner from "../Spinner";
import { GetProfileFetchData } from '../../redux/Profile/actions';

import Select from "react-dropdown-select";
import { Alert, Button, Modal } from 'react-bootstrap';
import { Markup } from 'interweave';
import ReactPaginate from 'react-paginate';
///////////////////////
import "./index.css";
import { TreeTable, TreeState } from 'cp-react-tree-table';
// import html2canvas from "html2canvas";
import axios from 'axios';
import Tabs from './Tabs';

class ManageCertificateComponent extends Component {
    blockchainselectindex = 0;
    selectedCustomDropdown = {};
    cohortsearchmode = 0;
    progressCheckStats = [];
    issuedCheckStats = [];
    Cohorts = [];
    dropdownCohortArray = {};
    selectedCohort = '';
    selectedCertType = '';
    selectedCertStatus = '';
    selectedDownloadType = '';
    progressselectindex = 0;
    issuedselectindex = 0;
    certtypeselectindex = 0;
    certstatusselectindex = 0;
    downloadtypeselectindex = 0;
    isconfirmemailfirst = 1;
    isconfirmpassfirst = 1;
    revokereason = "";

    mainPageCountProgress = 1;
    mainPageOffsetProgress = 0;
    initialMainPageIndexProgress = 0;

    mainPageCountIssued = 1;
    mainPageOffsetIssued = 0;
    initialMainPageIndexIssued = 0;

    ipAddress = "";

    maxitemsperpage = 50;
    is_cohort = false;
    isInProgressOrIssuedTab = "inprogress";
    CertType = [{ "label": "Degree", "value": "degree" }, { "label": "Migration", "value": "migration" }, { "label": "Transfer", "value": "transfer" }, { "label": "Awards", "value": "awards" }, { "label": "Relieving", "value": "relieving" }, { "label": "Bonafide", "value": "bonafide" }, { "label": "Medal", "value": "medal" }, { "label": "Transcript", "value": "transcript" }, { "label": "Semester-wise Grade card", "value": "semesterwisegradecard" }, { "label": "OpenBadges", "value": "openbadges" }, { "label": "OR", "value": "or" }];
    CertStatus = [{ "label": "All", "value": "all" }, { "label": "generated", "value": "generated" }, { "label": "PK received", "value": "PK received" }, { "label": "With Photo", "value": "With Photo" }, { "label": "Without Photo", "value": "Without Photo" }];
    DownloadType = [{ "label": "Selected Certificates", "value": "select" }, { "label": "All the Certificates", "value": "all" }];

    constructor(props) {
        super(props);
        this.state = {
            con: true,
            checkchange: true,
            ProgressStudentsData: [],
            IssuedStudentsData: [],
            PreviewCertificateStatus: false,
            alertData: {
                status: false,
                className: '',
                message: ''
            },
            showModal: false,
            showPhotoModal: false,
            showDownloadEmailModal: false,
            showIssueConfirmModal: false,
            showIssueBalanceModal: false,
            showRevokeConfirmModal: false,
            ModalContents: [],
            currentOffset: 0,
            is_validatepermission: false,
            searchValue: "",
            displayCohort: [],
            selectedPhotoContent: "",
            selectedPhotoTitle: "",
            downloadJson: false,
            downloadPdf: false,
            downloadPrint: false,
            downloadQRcode: false,
            isRenderedCohort: false
        }
        //this.GetStudents = this.GetStudents.bind(this);
        this.previewCertificate = this.previewCertificate.bind(this);
        this.sendCertificate = this.sendCertificate.bind(this);
        this.sendCertificateforReview = this.sendCertificateforReview.bind(this);
        this.download = this.download.bind(this);
        this.directDownload = this.directDownload.bind(this);
        // this.save_preview_image = this.save_preview_image.bind(this);
        this.UnholdStudents = this.UnholdStudents.bind(this);
        this.sendOpenBadges = this.sendOpenBadges.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onValidatef = this.onValidatef.bind(this);
        this.closeIssueConfirmModal = this.closeIssueConfirmModal.bind(this);
        this.closeRevokeConfirmModal = this.closeRevokeConfirmModal.bind(this);
        this.closeDownloadEmailModal = this.closeDownloadEmailModal.bind(this);
        this.closeIssueBalanceModal = this.closeIssueBalanceModal.bind(this);
        this.issueBalanceConfirm = this.issueBalanceConfirm.bind(this);
        this.issueConfirm = this.issueConfirm.bind(this);
        this.GetCohorts = this.GetCohorts.bind(this);
        this.selectCohortForProgress = this.selectCohortForProgress.bind(this);
        this.selectCohortForIssued = this.selectCohortForIssued.bind(this);
        this.selectRevokeForIssued = this.selectRevokeForIssued.bind(this);
        this.selectPageSize = this.selectPageSize.bind(this);
        this.refresh = this.refresh.bind(this);
        this.changeCertStatus = this.changeCertStatus.bind(this);


        localStorage.setItem('selectedpageindexforprogress', 0);
        localStorage.setItem('selectedpageindexforissued', 0);
        this.mainPageOffsetIssued = 0;
        this.mainPageOffsetProgress = 0;
        this.initialMainPageIndexIssued = 0;
        this.initialMainPageIndexProgress = 0;
    }

    selectPageSize = (e) => {
        let idx = e.target.selectedIndex;
        let value = e.target.options[idx].value;
        this.maxitemsperpage = value;
        localStorage.setItem('selectedpageindexforprogress', 0);
        localStorage.setItem('selectedpageindexforissued', 0);
        this.mainPageOffsetIssued = 0;
        this.mainPageOffsetProgress = 0;
        this.initialMainPageIndexIssued = 0;
        this.initialMainPageIndexProgress = 0;
        this.GetCohorts();
    }

    GetCohorts() {
        this.setState({ con: true });
        if (this.selectedCertType == "" || this.selectedCertType == null) this.selectedCertType = "degree";
        localStorage.setItem('selectedCertType', this.selectedCertType);
        let payload = { certtypevalue: this.selectedCertType };
        this.props.dispatch(GetCohortsFetchData(getCohorts, payload));
    }


    getJsonFileContent = async (url) => {
        console.log(url);
        const api_call = await fetch(url);
        const data = await api_call.json();
        return JSON.stringify(data);
    }
    getPdfFileContent = async (url) => {
        console.log(url);
        const api_call = await fetch(url);
        //const data = await api_call.json();

        return api_call.blob();
    }
    getPngFileContent = async (url) => {
        console.log(url);
        const api_call = await fetch(url);
        //const data = await api_call.json();        
        return api_call.blob();
    }

    async handleJsonDownload(url, exportName) {
        const jsondata = await this.getJsonFileContent(url);
        //*
        const blob = new Blob([jsondata], { type: 'application/json' });
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", await URL.createObjectURL(blob));
        downloadAnchorNode.setAttribute("download", exportName);
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    async handlePdfDownload(url, exportName) {
        const pdfdata = await this.getPdfFileContent(url);
        const blob = new Blob([pdfdata], { type: 'application/pdf' });
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", await URL.createObjectURL(blob));
        downloadAnchorNode.setAttribute("download", exportName);
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
        //*/
    }
    async handlePngDownload(url, exportName) {
        const pngdata = await this.getPdfFileContent(url);
        const blob = new Blob([pngdata], { type: 'image/png' });
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", await URL.createObjectURL(blob));
        downloadAnchorNode.setAttribute("download", exportName);
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
        //*/
    }

    handleMainPageClick(data) {
        let selected = data.selected;
        localStorage.setItem('selectedpageindexforprogress', selected * this.maxitemsperpage);
        this.mainPageOffsetProgress = selected * this.maxitemsperpage;
        this.initialMainPageIndexProgress = selected;
        this.GetStudentsForIssued(this.selectedCohort, this.mainPageOffsetProgress, this.mainPageOffsetIssued, this.state.searchValue, this.selectedCertType, 'progress', this.selectedCertStatus);
    }

    handleMainPageIssuedClick(data) {
        let selected = data.selected;
        localStorage.setItem('selectedpageindexforissued', selected * this.maxitemsperpage);
        this.mainPageOffsetIssued = selected * this.maxitemsperpage;
        this.initialMainPageIndexIssued = selected;
        this.GetStudentsForIssued(this.selectedCohort, this.mainPageOffsetProgress, this.mainPageOffsetIssued, this.state.searchValue, this.selectedCertType, 'progress', this.selectedCertStatus);
    }

    GetProfile() {
        this.setState({ con: true })
        this.props.dispatch(GetProfileFetchData(getProfile));
    }

    initCheckStatsinprogress(argstudents, type) {
        let data = [];
        for (let index = 0; index < argstudents.length; index++) {
            const parent = argstudents[index].data;
            let subItems = [];
            const children = argstudents[index].children;
            if (children !== null && children !== undefined && children.length > 0) {
                for (let i = 0; i < children.length; i++) {
                    const child = children[i].data;
                    let idval = child.cohortid + "=" + child.studentid;
                    let status = child.status;
                    let studentid = child.studentid;
                    //subItems.push({id: idval, checked: false});
                    data.push({ id: idval, status: status, studentid: studentid, checked: false });
                }
            }
            let idval = parent.cohortid + "=" + parent.studentid;
            data.push({ id: idval, status: "", studentid: "", checked: false });
        }
        if (type == 'progress') {
            this.progressCheckStats = data;
        } else if (type == 'issued') {
            this.issuedCheckStats = data;
        }
    }

    selectCohortForProgress = (e) => {
        let idx = e.target.selectedIndex;
        this.progressselectindex = idx;
        let value = e.target.options[idx].value;
        this.setState({ con: true });
        this.setState({ size: 1 });
        e.target.blur();
        this.selectedCohort = value;
        localStorage.setItem('selectedCohort', this.selectedCohort);
        localStorage.setItem('selectedpageindexforprogress', 0);
        localStorage.setItem('selectedpageindexforissued', 0);
        this.mainPageOffsetIssued = 0;
        this.mainPageOffsetProgress = 0;
        this.initialMainPageIndexIssued = 0;
        this.initialMainPageIndexProgress = 0;
        this.GetCohorts();
    }

    searchAction() {
        localStorage.setItem('selectedpageindexforprogress', 0);
        localStorage.setItem('selectedpageindexforissued', 0);
        this.mainPageOffsetIssued = 0;
        this.mainPageOffsetProgress = 0;
        this.initialMainPageIndexIssued = 0;
        this.initialMainPageIndexProgress = 0;
        this.GetCohorts();
    }

    checkenterkeyforsearch = (e) => {
        if (e.key == "Enter") {
            this.searchAction();
        }
    }

    selectCohortForIssued = (e) => {
        let idx = e.target.selectedIndex;
        this.issuedselectindex = idx;
        let value = e.target.options[idx].value;
        this.setState({ con: true });
        this.setState({ size: 1 });
        e.target.blur();
        this.selectedCohort = value;
        localStorage.setItem('selectedCohort', this.selectedCohort);
        localStorage.setItem('selectedpageindexforprogress', 0);
        localStorage.setItem('selectedpageindexforissued', 0);
        this.mainPageOffsetIssued = 0;
        this.mainPageOffsetProgress = 0;
        this.initialMainPageIndexIssued = 0;
        this.initialMainPageIndexProgress = 0;
        this.GetCohorts();
    }

    changeCertType = (e) => {
        let idx = e.target.selectedIndex;
        this.certtypeselectindex = idx;
        let value = e.target.options[idx].value;

        this.selectedCertType = value;
        localStorage.setItem('selectedCertType', this.selectedCertType);
        localStorage.setItem('selectedpageindexforprogress', 0);
        localStorage.setItem('selectedpageindexforissued', 0);
        this.mainPageOffsetIssued = 0;
        this.mainPageOffsetProgress = 0;
        this.initialMainPageIndexIssued = 0;
        this.initialMainPageIndexProgress = 0;
        this.GetCohorts();
    }

    changeCertStatus = (e) => {
        let idx = e.target.selectedIndex;
        this.certstatusselectindex = idx;
        let value = e.target.options[idx].value;
        this.selectedCertStatus = value;
        localStorage.setItem('selectedCertStatus', this.selectedCertStatus);
        localStorage.setItem('selectedpageindexforprogress', 0);
        localStorage.setItem('selectedpageindexforissued', 0);
        this.mainPageOffsetIssued = 0;
        this.mainPageOffsetProgress = 0;
        this.initialMainPageIndexIssued = 0;
        this.initialMainPageIndexProgress = 0;
        this.GetCohorts();
    }

    changeDownloadType = (e) => {
        let idx = e.target.selectedIndex;
        this.downloadtypeselectindex = idx;
        let value = e.target.options[idx].value;
        this.selectedDownloadType = value;
        localStorage.setItem('selectedDownloadType', this.selectedDownloadType);
    }

    selectRevokeForIssued = (e) => {

        var selectedItems = [];
        if (this.issuedCheckStats.length > 0) {
            for (let i = 0; i < this.issuedCheckStats.length; i++) {
                if (this.issuedCheckStats[i].checked) {
                    let cohortid = this.issuedCheckStats[i].id.split("=")[0];
                    let studentid = this.issuedCheckStats[i].id.split("=")[1];
                    if (cohortid != '' && studentid != '') {
                        selectedItems.push(this.issuedCheckStats[i].id);
                    }
                }
            }
        }
        if (selectedItems.length == 0) {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected students' } });
            window.scrollTo(0, 0);

        }
        else {
            this.isInProgressOrIssuedTab = "issued";
            this.setState({ showRevokeConfirmModal: true });
        }
    }

    GetStudentsForIssued(cohortId, pageOffset, pageOffsetIssued, searchQuery, certtypep, step, status = 'all') {
        this.setState({ con: true });
        this.closeAlert();
        /*
        ///temp///
        cohortId=[];
        cohortId.push("rv engineering-ug-2023-regular-odd-semester1");
        cohortId.push("rv engineering-pg-2024-regular-even-semester2");
        /////////
        //*/
        let cohortsearchmodelstrg = localStorage.getItem('cohortsearchmode');
        if (cohortsearchmodelstrg == undefined || cohortsearchmodelstrg == null || cohortsearchmodelstrg == "") cohortsearchmodelstrg = 0;
        var selectedCohortJson = "";
        if (cohortsearchmodelstrg == 1) {
            if (step == "progress") selectedCohortJson = this.getSelectedCohortDropdownValueProgress();
            else if (step == "issued") selectedCohortJson = this.getSelectedCohortDropdownValueProgress();
        }
        try {
            localStorage.setItem('selectedCohortJson', JSON.stringify(selectedCohortJson));
            localStorage.setItem('selectedCertType', certtypep);
        } catch (error) {

        }
        let payload = {
            key: "cohort",
            //value: cohortId,
            cohort: cohortId,
            certtypevalue: certtypep,
            pageOffset: pageOffset,
            pageOffsetIssued: pageOffsetIssued,
            maxitemsperpage: this.maxitemsperpage,
            searchValue: searchQuery,
            step: step,
            status: status,
            selectedCohortJson: selectedCohortJson
        };
        // console.log("GetStudentsFetchData payload : ", payload);
        this.props.dispatch(GetStudentsFetchData(getStudentsForStep, payload));
    }

    componentDidMount() {
        axios.get('https://jsonip.com/')
            .then(response => {
                this.ipAddress = response.data.ip;
            })
            .catch(error => console.log(error));
        let cohortid = localStorage.getItem('selectedCohort');
        if (cohortid == null || cohortid == undefined) {
            cohortid = this.Cohorts[0];
        }
        else {
            this.Cohorts.length > 0
                && this.Cohorts.map((item, i) => {
                    if (item.value == cohortid) {
                        this.progressselectindex = i;
                        this.issuedselectindex = i;
                    }

                }, this);
        }
        this.selectedCohort = cohortid;

        let selectedcerttypeid = localStorage.getItem('selectedCertType');
        if (selectedcerttypeid == null) {
            selectedcerttypeid = "degree";
            localStorage.setItem("selectedCertType", "degree");
        }
        else {
            this.CertType.length > 0
                && this.CertType.map((item, i) => {
                    if (item.value == selectedcerttypeid) {
                        this.certtypeselectindex = i;
                    }

                }, this);
        }
        this.selectedCertType = selectedcerttypeid;

        let selectedDownloadType = localStorage.getItem('selectedDownloadType');
        if (selectedDownloadType == null) selectedDownloadType = "select";
        else {
            this.DownloadType.length > 0
                && this.DownloadType.map((item, i) => {
                    if (item.value == selectedDownloadType) {
                        this.downloadtypeselectindex = i;
                    }

                }, this);
        }
        this.selectedDownloadType = selectedDownloadType;
        localStorage.setItem('selectedpageindexforprogress', 0);
        localStorage.setItem('selectedpageindexforissued', 0);
        this.mainPageOffsetIssued = 0;
        this.mainPageOffsetProgress = 0;
        this.GetProfile();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.isRenderedCohort == true) {
            this.setState({ isRenderedCohort: false });
            // localStorage.setItem('selectedpageindexforprogress', 0);
            // localStorage.setItem('selectedpageindexforissued', 0);
            // this.mainPageOffsetIssued = 0;
            // this.mainPageOffsetProgress = 0;
            // this.initialMainPageIndexIssued = 0;
            // this.initialMainPageIndexProgress = 0;
            // this.GetStudentsForIssued(this.selectedCohort,this.mainPageOffsetIssued,this.state.searchValue,this.selectedCertType,'issued',this.selectedCertStatus);
            this.GetStudentsForIssued(this.selectedCohort, this.mainPageOffsetProgress, this.mainPageOffsetIssued, this.state.searchValue, this.selectedCertType, 'progress', this.selectedCertStatus);
        }

        if (prevProps.BatchStatus) {
            this.setState({ con: false });
            if (prevProps.BatchStatus.Status === 200) {
                this.setState({ alertData: { status: 'Success', className: 'success', message: prevProps.BatchStatus.message } });
            }
            else {
                this.setState({ alertData: { status: 'warning', className: 'warning', message: prevProps.BatchStatus.message } });
            }
        }

        if (prevProps.ResetCertificateStatus) {
            if (prevProps.ResetCertificateStatus.Status === 200) {
                this.GetCohorts();
                this.setState({ alertData: { status: 'Success', className: 'success', message: prevProps.ResetCertificateStatus.message } });
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }
            else {
                this.setState({ alertData: { status: 'Success', className: 'warning', message: prevProps.ResetCertificateStatus.message } });
                window.scrollTo(0, 0);
                this.setState({ con: false });

            }
        }

        if (prevProps.UnholdStudentsStatus) {
            if (prevProps.UnholdStudentsStatus.Status === 200) {
                this.GetCohorts();
                this.setState({ alertData: { status: 'Success', className: 'success', message: prevProps.UnholdStudentsStatus.message } });
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }
            else {
                this.setState({ alertData: { status: 'Success', className: 'warning', message: prevProps.UnholdStudentsStatus.message } });
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }
        }


        if (prevProps.Profile) {
            if (prevProps.Profile.Status === 200) {
                //this.setState({ Profile: prevProps.Profile.Data });
                if (prevProps.Profile.Data.validate == true) {
                    this.setState({ is_validatepermission: true });
                }
                else {
                    this.setState({ is_validatepermission: false });
                }

                if (prevProps.Profile.Data.blockchain == "bitcoin") {
                    this.blockchainselectindex = 1;
                }
                else if (prevProps.Profile.Data.blockchain == "ethereum") {
                    this.blockchainselectindex = 2;
                }
                //this.setState({cohortsearchmode:prevProps.Profile.Data.cohortsearchmode, cohortsplitlength:prevProps.Profile.Data.cohortsplitlength}); 
                this.GetCohorts();
            }
        }

        if (prevProps.IssueConfirm) {
            if (prevProps.IssueConfirm.Status === 200) {
                if (this.blockchainselectindex == 0) {
                    alert("Please select blockchain");
                }
                else {
                    //this.setState({con:true});
                    this.setState({ alertData: { status: 'Success', className: 'success', message: 'Certificate issuance may take several minutes or hours.' } });
                    window.scrollTo(0, 0);
                    var selectedItems = [];
                    if (this.is_cohort == false) {
                        if (this.isInProgressOrIssuedTab == "inprogress") {
                            if (this.progressCheckStats.length > 0) {
                                for (let i = 0; i < this.progressCheckStats.length; i++) {
                                    if (this.progressCheckStats[i].checked) {
                                        selectedItems.push(this.progressCheckStats[i].id);
                                    }
                                }
                            }

                            if (selectedItems.length === 0) {
                                this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected students' } });
                                window.scrollTo(0, 0);
                                this.setState({ con: false });
                            }
                            else {
                                let payload = {
                                    selectedItems: selectedItems,
                                    certtype: this.selectedCertType,
                                    ipaddress: this.ipAddress
                                };
                                this.props.dispatch(IssueCertificate(issuecertificate, payload));
                            }
                        }
                        else if (this.isInProgressOrIssuedTab == "issued") {
                            if (this.issuedCheckStats.length > 0) {
                                for (let i = 0; i < this.issuedCheckStats.length; i++) {
                                    if (this.issuedCheckStats[i].checked) {
                                        let cohortid = this.issuedCheckStats[i].id.split("=")[0];
                                        let studentid = this.issuedCheckStats[i].id.split("=")[1];
                                        if (cohortid != '' && studentid != '') {
                                            selectedItems.push(this.issuedCheckStats[i].id);
                                        }
                                    }
                                }
                            }
                            if (selectedItems.length == 0) {
                                this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected students' } });
                                window.scrollTo(0, 0);
                            }
                            else if (this.revokereason == "") {
                                this.setState({ alertData: { status: 'empty', className: 'warning', message: 'Please enter revoke reason.' } });
                                window.scrollTo(0, 0);
                            }
                            else {
                                let payload = {
                                    selectedItems: selectedItems,
                                    certtype: this.selectedCertType,
                                    revokereason: this.revokereason
                                };
                                this.props.dispatch(RevokeCertificate(revokeCertificate, payload));
                            }
                        }
                    }
                    else {
                        let payload = {
                            selectedItems: selectedItems,
                            certtype: this.selectedCertType,
                            selectedCohort: this.selectedCohort,
                            ipaddress: this.ipAddress
                        };
                        this.props.dispatch(IssueCertificate(issuecertificate, payload));
                    }
                }
            }

            else if (prevProps.IssueConfirm.Status === 202) {
                this.GetCohorts();
            }
            else {
                this.setState({ alertData: { status: 'warning', className: 'warning', message: prevProps.IssueConfirm.message } });
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }
        }

        if (prevProps.ValidateStatus) {
            if (prevProps.ValidateStatus.Status === 200) {
                if (this.blockchainselectindex == 0) {
                    alert("Please select blockchain");
                }
                else {
                    this.setState({ alertData: { status: 'Success', className: 'success', message: prevProps.ValidateStatus.message } });
                    window.scrollTo(0, 0);
                    this.setState({ con: false });
                    this.GetCohorts();
                }

            }
            else {
                this.setState({ alertData: { status: 'warning', className: 'warning', message: prevProps.ValidateStatus.message } });
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }
        }

        if (prevProps.IssueStatus) {
            this.setState({ con: false });
            if (prevProps.IssueStatus.Status === 200) {
                this.setState({ alertData: { status: 'Success', className: 'success', message: prevProps.IssueStatus.message } });
                window.scrollTo(0, 0);
                this.GetCohorts();
            }
            else {
                if (prevProps.IssueStatus.message.indexOf("No such file or directory") != -1) {
                    this.setState({ alertData: { status: 'warning', className: 'warning', message: 'Please generate certificates firstly' } });
                    window.scrollTo(0, 0);
                    this.setState({ con: false });
                }
                else {
                    if (prevProps.IssueStatus.message.indexOf("InsufficientFundsError") != -1 || prevProps.IssueStatus.message.indexOf("Broadcasting failed") != -1) {
                        this.setState({ alertData: { status: 'warning', className: 'warning', message: 'The balance is not sufficient' } });
                        window.scrollTo(0, 0);
                        this.setState({ con: false });
                    }
                    else {
                        this.setState({ alertData: { status: 'warning', className: 'warning', message: prevProps.IssueStatus.message } });
                        window.scrollTo(0, 0);
                        this.setState({ con: false });
                    }
                }
            }
        }

        if (prevProps.Students) {
            this.setState({ con: false });
            let progressStudentsData;
            let issuedStudentsData;
            if (prevProps.Students.Status === 200) {
                if (prevProps.Students.step == 'progress') {
                    this.initCheckStatsinprogress(prevProps.Students.Data.mockData, 'progress');
                    progressStudentsData = TreeState.create(prevProps.Students.Data.mockData);
                    if (progressStudentsData.data.length > 0)
                        progressStudentsData = TreeState.expandAll(progressStudentsData);
                    this.setState({ ProgressStudentsData: progressStudentsData });
                    try {
                        this.mainPageCountProgress = prevProps.Students.Data.mockData[0].pagecount / this.maxitemsperpage;
                    }
                    catch (error) {
                        this.mainPageCountProgress = 1;
                        this.setState({ con: false });
                    }
                    this.initCheckStatsinprogress(prevProps.Students.Data.mockData_Issued, 'issued');
                    issuedStudentsData = TreeState.create(prevProps.Students.Data.mockData_Issued);
                    if (issuedStudentsData.data.length > 0)
                        issuedStudentsData = TreeState.expandAll(issuedStudentsData);
                    this.setState({ IssuedStudentsData: issuedStudentsData });
                    try {
                        this.mainPageCountIssued = prevProps.Students.Data.mockData_Issued[0].pagecount / this.maxitemsperpage;
                    }
                    catch (error) {
                        this.mainPageCountIssued = 1;
                        this.setState({ con: false });
                    }
                }
                else if (prevProps.Students.step == 'issued') {
                }
            }
            else {
                this.setState({ alertData: { status: 'Success', className: 'warning', message: prevProps.Students.message } });
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }
        }
        if (prevProps.RevokeStatus) {
            this.GetCohorts();
            this.setState({ alertData: { status: 'Success', className: 'success', message: prevProps.RevokeStatus.message } });
            window.scrollTo(0, 0);
            this.setState({ con: false });
            this.setState({ RevokeStatus: prevProps.RevokeStatus });
        }
        if (prevProps.SendforReviewStatus) {
            this.setState({ con: false });
            if (prevProps.SendforReviewStatus.Status === 200) {
                this.setState({ alertData: { status: 'Success', className: 'success', message: prevProps.SendforReviewStatus.message } });
                window.scrollTo(0, 0);
            }
            else {
                this.setState({ alertData: { status: 'warning', className: 'warning', message: prevProps.SendforReviewStatus.message } });
                window.scrollTo(0, 0);
            }
        }
        if (prevProps.SendStatus) {
            this.setState({ con: false });
            if (prevProps.SendStatus.Status === 200) {
                this.setState({ con: false });
                this.setState({ alertData: { status: 'Success', className: 'success', message: prevProps.SendStatus.message } });
                window.scrollTo(0, 0);
                this.GetCohorts();
            }
            else {
                this.setState({ alertData: { status: 'warning', className: 'warning', message: prevProps.SendStatus.message } });
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }
        }
        if (prevProps.GenerateStatus) {

            if (prevProps.GenerateStatus.Status === 200) {
                this.GetCohorts();
                if (prevProps.GenerateStatus.message === "No such file or directory.") {
                    //this.setState({alertData:{status:'Success',className: 'warning', message: "There is already a generated certificate."}});
                    this.setState({ alertData: { status: 'Success', className: 'warning', message: "Please check selected items again." } });
                }
                else {
                    this.setState({ alertData: { status: 'Success', className: 'success', message: prevProps.GenerateStatus.message } });
                }
                window.scrollTo(0, 0);
                this.setState({ con: false });
                this.setState({ GenerateStatus: prevProps.GenerateStatus });
            }
            else {
                this.setState({ alertData: { status: 'Success', className: 'warning', message: prevProps.GenerateStatus.message } });
                window.scrollTo(0, 0);
                this.setState({ con: false });
                this.setState({ GenerateStatus: prevProps.GenerateStatus });

            }
        }
        if (prevProps.BakeStatus) {
            if (prevProps.BakeStatus.Status === 200) {
                this.GetCohorts();
                if (prevProps.BakeStatus.message.indexOf("Sending") === -1) {
                    this.setState({ alertData: { status: 'Success', className: 'success', message: prevProps.BakeStatus.message } });
                }
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }
            else {
                this.setState({ alertData: { status: 'Success', className: 'warning', message: prevProps.BakeStatus.message } });
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }
        }
        if (prevProps.PreviewCertificateStatus) {
            if (prevProps.PreviewCertificateStatus.Status === 200) {
                this.setState({ con: false });
                let certList = prevProps.PreviewCertificateStatus.PreviewCert;
                this.pageCount = certList.length;
                this.setState({ showModal: true, ModalContents: certList });
            }
            else {
                if (prevProps.PreviewCertificateStatus.message == "Cannot read property 'displayHtml' of null" || prevProps.PreviewCertificateStatus.message == "Unexpected end of JSON input") {
                    this.setState({ alertData: { status: 'No available certificates', className: 'warning', message: 'There is no generated certificates' } });
                }
                else {
                    this.setState({ alertData: { status: 'No available certificates', className: 'warning', message: prevProps.PreviewCertificateStatus.message } });
                }
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }
        }
        if (prevProps.PreviewStudentPhotoStatus) {
            if (prevProps.PreviewStudentPhotoStatus.Status === 200) {
                this.setState({ con: false });
                let photoContent = prevProps.PreviewStudentPhotoStatus.photoContent;
                let photoTitle = prevProps.PreviewStudentPhotoStatus.photoTitle;
                // this.pageCount = certList.length;
                this.setState({ showPhotoModal: true, selectedphotoContent: photoContent, selectedPhotoTitle: photoTitle });
            }
            else {
                if (prevProps.PreviewStudentPhotoStatus.message == "Cannot read property 'displayHtml' of null" || prevProps.PreviewStudentPhotoStatus.message == "Unexpected end of JSON input") {
                    this.setState({ alertData: { status: 'No available certificates', className: 'warning', message: 'There is no generated certificates' } });
                }
                else {
                    this.setState({ alertData: { status: 'No available certificates', className: 'warning', message: prevProps.PreviewStudentPhotoStatus.message } });
                }
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }

        }
        if (prevProps.DownloadPreviewImageStatus) {
            if (prevProps.DownloadPreviewImageStatus.Status === 200) {
                this.setState({ alertData: { status: 'Success', className: 'success', message: "Download successfully." } });
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }
            else {
                if (prevProps.DownloadPreviewImageStatus.message == "Cannot read property 'displayHtml' of null" || prevProps.DownloadPreviewImageStatus.message == "Unexpected end of JSON input") {
                    this.setState({ alertData: { status: 'No available certificates', className: 'warning', message: 'There is no generated certificates' } });
                }
                else {
                    this.setState({ alertData: { status: 'No available certificates', className: 'warning', message: prevProps.DownloadPreviewImageStatus.message } });
                }
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }
        }
        if (prevProps.Cohorts) {
            this.setState({ con: false });
            if (prevProps.Cohorts.Status === 200) {
                try {
                    this.Cohorts = prevProps.Cohorts.Data;
                    //this.Cohorts = prevProps.Students.Data.cohortData; 
                    this.dropdownCohortArray = prevProps.Cohorts.cohortdropwdownarray;
                    let cohortid = localStorage.getItem('selectedCohort');
                    if (cohortid == null) {
                        if (this.Cohorts.length > 0) {
                            localStorage.setItem('selectedCohort', this.Cohorts[0]["value"]);
                            this.selectedCohort = this.Cohorts[0]["value"];
                            this.setState({ displayCohort: [{ label: this.Cohorts[0].label, value: this.Cohorts[0].value }] });
                        }
                    }
                    else {
                        this.Cohorts.length > 0
                            && this.Cohorts.map((item, i) => {
                                if (item.value == cohortid) {
                                    this.selectedCohort = cohortid;
                                    this.progressselectindex = i;
                                    this.issuedselectindex = i;
                                    this.setState({ displayCohort: [{ label: item.label, value: item.value }] });
                                }

                            }, this);
                    }
                    localStorage.setItem('cohortsearchmode', prevProps.Cohorts.cohortsearchmode)
                    this.setState({ isRenderedCohort: true });
                } catch (error) {
                    this.setState({ alertData: { status: 'warning', className: 'warning', message: "There are no added cohorts" } });
                }
            }
            else {
                this.enabledbt = false;
                this.enablenextstep = false;
                this.setState({ alertData: { status: 'warning', className: 'warning', message: prevProps.Cohorts.message } });
            }
        }
    }

    sleep(milliseconds) {
        var start = new Date().getTime();
        for (var i = 0; i < 1e7; i++) {
            if ((new Date().getTime() - start) > milliseconds) {
                break;
            }
        }
    }
    //send certificate
    sendCertificate() {
        this.setState({ con: true });
        var selectedItems = [];
        if (this.issuedCheckStats.length > 0) {
            for (let i = 0; i < this.issuedCheckStats.length; i++) {
                if (this.issuedCheckStats[i].checked) {
                    selectedItems.push(this.issuedCheckStats[i].id);
                }
            }
        }
        if (selectedItems.length === 0) {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected students' } });
            window.scrollTo(0, 0);
            this.setState({ con: false });
        } else {
            let payload = {
                selectedItems: selectedItems,
                certtype: this.selectedCertType,
                isdownload: false,
                ipaddress: this.ipAddress
            };
            this.props.dispatch(SendCertificate(sendCertificate, payload));
        }
    }
    //refresh certificate
    refresh() {
        this.setState({ con: true });
        this.GetCohorts();
    }
    //get email to download
    downloadConfirm() {
        this.is_cohort = false;
        var isselectedcorrectitem = 1;
        var selectedItems = [];
        if (this.issuedCheckStats.length > 0) {
            for (let i = 0; i < this.issuedCheckStats.length; i++) {
                if (this.issuedCheckStats[i].checked) {
                    selectedItems.push(this.issuedCheckStats[i].id);
                    if (this.issuedCheckStats[i].status.indexOf("Certificate") == -1 && this.issuedCheckStats[i].studentid !== "") {
                        isselectedcorrectitem = 0;
                    }
                }
            }
        }
        if (selectedItems.length === 0) {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected items' } });
            window.scrollTo(0, 0);
        }
        else if (isselectedcorrectitem == 0) {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'Please select correct items' } });
            window.scrollTo(0, 0);
        }
        else {
            this.isInProgressOrIssuedTab = "issued";
            this.setState({ showDownloadEmailModal: true });
        }
    }
    //send certificate
    download() {
        this.setState({ con: true });
        var selectedItems = [];
        this.setState({ showDownloadEmailModal: false, showRevokeConfirmModal: false, confirmemail: "", confirmpassword: "" });
        if (!this.state.downloadJson && !this.state.downloadPdf && !this.state.downloadPrint && !this.state.downloadQRcode) {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected certificates type. You must select certificates type at least one.' } });
            window.scrollTo(0, 0);
            this.setState({ con: false });
        }
        else {
            if (this.issuedCheckStats.length > 0) {
                for (let i = 0; i < this.issuedCheckStats.length; i++) {
                    if (this.issuedCheckStats[i].checked) {
                        selectedItems.push(this.issuedCheckStats[i].id);
                    }
                }
            }
            if (selectedItems.length === 0) {
                this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected students' } });
                window.scrollTo(0, 0);
                this.setState({ con: false });
            } else {
                // let receive_email = document.getElementById("downloademailid").value;
                let payload = {
                    selectedItems: selectedItems,
                    certtype: this.selectedCertType,
                    // email: receive_email,
                    isdownload: true,
                    downloadjson: this.state.downloadJson,
                    downloadpdf: this.state.downloadPdf,
                    downloadprint: this.state.downloadPrint,
                    downloadqrcode: this.state.downloadQRcode,
                    downloadtype: this.selectedDownloadType,
                    ipaddress: this.ipAddress
                };
                this.props.dispatch(SendCertificate(sendCertificate, payload));
            }
        }
        this.state.downloadJson = false;
        this.state.downloadPdf = false;
        this.state.downloadPrint = false;
        this.state.downloadQRcode = false;
    }
    sendOpenBadges() {

        this.setState({ con: true });
        var selectedItems = [];
        if (this.issuedCheckStats.length > 0) {
            for (let i = 0; i < this.issuedCheckStats.length; i++) {
                if (this.issuedCheckStats[i].checked) {
                    selectedItems.push(this.issuedCheckStats[i].id);
                }
            }
        }
        if (selectedItems.length === 0) {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected students' } });
            window.scrollTo(0, 0);
            //this.sleep(3000);
            this.setState({ con: false });
        } else {
            window.scrollTo(0, 0);
            let payload = {
                selectedItems: selectedItems,
                certtype: this.selectedCertType,
                issendopenbadges: true,
            };
            this.props.dispatch(BakeOpenBadges(bakeopenbadgesurl, payload));
        }
    }

    //Generate certificate
    generateCertificate() {
        if (this.blockchainselectindex == 0) {
            alert("Please select blockchain");
            return;
        }
        this.setState({ con: true });
        var selectedItems = [];
        var pending_exist = false;
        if (this.progressCheckStats.length > 0) {
            for (let i = 0; i < this.progressCheckStats.length; i++) {
                if (this.progressCheckStats[i].checked) {
                    if (this.progressCheckStats[i].status == "Invite pending") pending_exist = true;
                    else selectedItems.push(this.progressCheckStats[i].id);
                }
            }
        }
        if (selectedItems.length === 0) {
            if (pending_exist == true) this.setState({ alertData: { status: 'empty', className: 'warning', message: 'All the selected students has Invite pending status' } });
            else this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected students' } });
            window.scrollTo(0, 0);
            //this.sleep(3000);
            this.setState({ con: false });
        } else {
            if (pending_exist == true) {
                this.setState({ alertData: { status: 'empty', className: 'warning', message: 'Some selected students has Invite pending status' } });
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }
            else {
                this.setState({ alertData: { status: 'Success', className: 'success', message: 'Certificate generation may take several minutes.' } });
                window.scrollTo(0, 0);
                this.setState({ con: false });
                let payload = {
                    selectedItems: selectedItems,
                    certtype: this.selectedCertType,
                    ipaddress: this.ipAddress
                };
                this.props.dispatch(GenerateCertificate(generatecertificate, payload));
            }
            //this.sleep(3000);
        }
    }

    batchPrintPdfCertificate() {
        this.setState({ con: true });
        let payload = {
            cohortid: this.selectedCohort,
            batchtype: "generatecertificate"
        };
        this.props.dispatch(BatchCertificate(batchprocess, payload));
    }

    generateBatchCertificate() {
        this.setState({ con: true });
        let payload = {
            cohortid: this.selectedCohort,
            batchtype: "generatecertificate"
        };
        this.props.dispatch(BatchCertificate(batchprocess, payload));
    }

    ResetCertificate() {
        this.setState({ con: true });
        var selectedItems = [];
        if (this.progressCheckStats.length > 0) {
            for (let i = 0; i < this.progressCheckStats.length; i++) {
                if (this.progressCheckStats[i].checked) {
                    selectedItems.push(this.progressCheckStats[i].id);
                }
            }
        }
        if (selectedItems.length === 0) {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected students' } });
            window.scrollTo(0, 0);
            //this.sleep(3000);
            this.setState({ con: false });
        } else {
            window.scrollTo(0, 0);

            let payload = {
                selectedItems: selectedItems,
                certtype: this.selectedCertType,
            };
            this.props.dispatch(ResetCertificateAction(resetcertificateURL, payload));
        }

    }
    sendCertificateforReview() {
        this.setState({ con: true });
        var selectedItems = [];
        if (this.progressCheckStats.length > 0) {
            for (let i = 0; i < this.progressCheckStats.length; i++) {
                if (this.progressCheckStats[i].checked) {
                    selectedItems.push(this.progressCheckStats[i].id);
                }
            }
        }
        if (selectedItems.length === 0) {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected students' } });
            window.scrollTo(0, 0);
            //this.sleep(3000);
            this.setState({ con: false });
        } else {
            window.scrollTo(0, 0);

            let payload = {
                selectedItems: selectedItems,
                certtype: this.selectedCertType,
            };
            this.props.dispatch(SendCertificateforReview(sendCertificateforReviewurl, payload));
        }

    }
    //Unhold cohortmembers
    UnholdStudents() {
        this.setState({ con: true });
        var selectedItems = [];
        if (this.issuedCheckStats.length > 0) {
            for (let i = 0; i < this.issuedCheckStats.length; i++) {
                if (this.issuedCheckStats[i].checked && this.issuedCheckStats[i].status == "Hold") {
                    selectedItems.push(this.issuedCheckStats[i].id);
                }
            }
        }
        if (selectedItems.length === 0) {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected hold students' } });
            window.scrollTo(0, 0);
            this.setState({ con: false });
        } else {
            let payload = {
                selectedItems: selectedItems,
                certtype: this.selectedCertType,
                isdownload: false
            };
            this.props.dispatch(UnholdStudents(unholdStudents, payload));
        }
    }

    ///tyleesak end
    bakeOpenBadges() {
        this.setState({ con: true });
        var selectedItems = [];
        if (this.progressCheckStats.length > 0) {
            for (let i = 0; i < this.progressCheckStats.length; i++) {
                if (this.progressCheckStats[i].checked) {
                    selectedItems.push(this.progressCheckStats[i].id);
                }
            }
        }
        if (selectedItems.length === 0) {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected students' } });
            window.scrollTo(0, 0);
            //this.sleep(3000);
            this.setState({ con: false });
        } else {
            this.setState({ alertData: { status: 'Success', className: 'success', message: 'The baking may take several minutes.' } });
            window.scrollTo(0, 0);
            this.setState({ con: false });

            let payload = {
                selectedItems: selectedItems,
                certtype: this.selectedCertType,
                issendopenbadges: false,
            };
            this.props.dispatch(BakeOpenBadges(bakeopenbadgesurl, payload));
            //this.sleep(3000);

        }
    }
    //Generate certificate
    previewCertificate() {
        this.setState({ con: true });
        var selectedItems = [];
        if (this.progressCheckStats.length > 0) {
            for (let i = 0; i < this.progressCheckStats.length; i++) {
                if (this.progressCheckStats[i].checked) {
                    selectedItems.push(this.progressCheckStats[i].id);
                }
            }
        }
        if (selectedItems.length === 0) {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected students' } });
            window.scrollTo(0, 0);
            this.setState({ con: false });
        } else {
            this.closeAlert();
            let payload = {
                selectedItems: selectedItems,
                certtype: this.selectedCertType,
            };
            this.props.dispatch(PreviewCertificate(previewcertificate, payload));
        }
    }

    async directDownload() {
        this.setState({ con: true });
        var selectedItems = [];
        if (this.progressCheckStats.length > 0) {
            for (let i = 0; i < this.progressCheckStats.length; i++) {
                if (this.progressCheckStats[i].checked && this.progressCheckStats[i].status.indexOf("generated") !== -1) {
                    selectedItems.push(this.progressCheckStats[i].id);
                }
            }
            if (selectedItems.length === 0) {
                this.setState({ alertData: { status: 'empty', className: 'warning', message: 'Plesae select correct items.' } });
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }
            else if (selectedItems.length > 250) {
                this.setState({ alertData: { status: 'empty', className: 'warning', message: 'Please select up to 100 of students.' } });
                window.scrollTo(0, 0);
                this.setState({ con: false });
            }
            else {
                this.closeAlert();
                let payload = {
                    selectedItems: selectedItems,
                    certtype: this.selectedCertType,
                };

                var Token = 'bearer ' + localStorage.getItem('Token');
                const headers = {
                    'Authorization': Token,
                }
                axios.post(downloadPreviewImage, payload, {
                    credentials: "same-origin", responseType: 'blob', timeout: 3600000, headers: headers
                }).then(response => {
                    // console.log("download response: ", response);
                    if (response.status == "200") {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'file.zip');
                        document.body.appendChild(link);
                        link.click();
                        this.setState({ alertData: { status: 'Success', className: 'success', message: 'Download successfully.' } });
                        window.scrollTo(0, 0);
                        this.setState({ con: false });
                    }
                    else if (response.status == "400") {
                        this.setState({ con: false });
                        this.setState({ alertData: { status: 'empty', className: 'warning', message: response.statusText } });
                    }
                    else {
                        this.setState({ con: false });
                        this.setState({ alertData: { status: 'empty', className: 'warning', message: 'Downloading preview files is failed.' } });
                    }

                }).catch((error) => {
                    const reader = new FileReader();
                    const readPromise = new Promise((resolve, reject) => {
                        reader.onload = function () {
                            resolve(this.result);
                        };
                        reader.onerror = function () {
                            reject(new Error('Error on reading download error message'));
                        };
                        reader.readAsText(error.response.data);
                    });
                    readPromise.then(data => {
                        console.log("download error:", data);
                        this.setState({ con: false });
                        this.setState({ alertData: { status: 'empty', className: 'warning', message: data } });
                    }).catch(err => {
                        console.log("download error:", err);
                        this.setState({ con: false });
                        this.setState({ alertData: { status: 'empty', className: 'warning', message: err } });
                    });
                });
            }
        }
        else {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected students' } });
            window.scrollTo(0, 0);
            this.setState({ con: false });
        }
    }

    previewCertificateForIssued(coid, emid, certtype) {
        this.setState({ con: true });
        var selectedItems = [];
        selectedItems.push(coid + "=" + emid);
        if (selectedItems.length === 0) {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected items' } });
            window.scrollTo(0, 0);
            this.setState({ con: false });
        } else {
            this.closeAlert();
            let payload = {
                selectedItems: selectedItems,
                certtype: this.selectedCertType,
            };
            this.props.dispatch(PreviewCertificate(previewcertificate, payload));
        }
    }

    previewStudentPhoto(coid, emid) {
        this.setState({ con: true });
        this.closeAlert();
        let payload = {
            cohortid: coid,
            studentid: emid,
            certtype: localStorage.getItem("selectedCertType"),
        };
        this.props.dispatch(PreviewStudentPhoto(previewstudentphoto, payload));
    }

    closeModal() {
        this.setState({ currentOffset: 0 });
        this.setState({ showModal: false });
        this.setState({ showPhotoModal: false });
    }

    closePhotoModal() {
        this.setState({ showPhotoModal: false });
    }

    previewCertificateForRefresh() {
        var selectedItems = [];
        if (this.progressCheckStats.length > 0) {
            for (let i = 0; i < this.progressCheckStats.length; i++) {
                if (this.progressCheckStats[i].checked) {
                    selectedItems.push(this.progressCheckStats[i].id);
                }
            }
        }
        if (selectedItems.length === 0) {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected students' } });
            window.scrollTo(0, 0);
            this.setState({ con: false });
        } else {
            this.closeAlert();
            let payload = {
                selectedItems: selectedItems,
                certtype: this.selectedCertType,
            };
            this.props.dispatch(PreviewCertificate(previewcertificate, payload));
        }
    }
    onValidatef(cohortmemberid) {
        let payload = {
            cohortmemberid: cohortmemberid,
            certtype: this.selectedCertType,
            cohort: this.selectedCohort,
            selectedCohortJson: localStorage.getItem('selectedCohortJson')
        };
        this.setState({ con: true });
        this.props.dispatch(ValidateAction(updatevalidate, payload));
    }
    closeIssueConfirmModal() {
        this.isconfirmemailfirst = 1;
        this.isconfirmpassfirst = 1;
        this.setState({ showIssueConfirmModal: false, confirmemail: "", confirmpassword: "" });
    }
    closeIssueBalanceModal() {
        this.setState({ showIssueBalanceModal: false });
    }
    closeRevokeConfirmModal() {
        this.isconfirmemailfirst = 1;
        this.isconfirmpassfirst = 1;
        this.setState({ showRevokeConfirmModal: false, confirmemail: "", confirmpassword: "" });
    }

    async issueBalanceConfirm() {
        this.setState({ con: true });
        let res = await issueBalance(true);
        if (res > -1) {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'Send a request to the CertOnce team.' } });
        } else {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'Send a request to the CertOnce team, But failed by incorrect email setting.' } });
        }
        this.setState({ showIssueBalanceModal: false });
        this.setState({ con: false });
    }

    issueConfirm() {
        let confirmemail = document.getElementById("emailid").value;
        let confirmpassword = document.getElementById("passwordid").value;
        try {
            let revokereason = document.getElementById("revokereason").value;
            this.revokereason = revokereason;
        }
        catch (err) {
            console.log("issue:", err);
        }
        this.isconfirmemailfirst = 1;
        this.isconfirmpassfirst = 1;
        this.setState({ showIssueConfirmModal: false, showRevokeConfirmModal: false, confirmemail: "", confirmpassword: "" });
        if (confirmemail == "") {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'Please input email.' } });
            window.scrollTo(0, 0);
        }
        else if (confirmpassword == "") {
            this.setState({ alertData: { status: 'empty', className: 'warning', message: 'Please input password.' } });
            window.scrollTo(0, 0);
        }
        else {
            let selectedItemsLen = 0;
            for (let i = 0; i < this.progressCheckStats.length; i++) {
                if (this.progressCheckStats[i].checked && this.progressCheckStats[i].status.indexOf("generated") !== -1) {
                    selectedItemsLen++;
                }
            }
            let payload = { email: confirmemail, pass: confirmpassword };
            this.props.dispatch(IssueConfirm(issueconfirm, payload));
        }
    }

    closeDownloadEmailModal() {
        this.setState({ showDownloadEmailModal: false, confirmemail: "" });
    }

    async issueCertificate(iscohort = false) {
        if (iscohort == false) {
            this.is_cohort = false;
            var isselectedcorrectitem = 1;
            var selectedItems = [];
            if (this.progressCheckStats.length > 0) {
                for (let i = 0; i < this.progressCheckStats.length; i++) {
                    if (this.progressCheckStats[i].checked) {
                        selectedItems.push(this.progressCheckStats[i].id);
                        if (this.progressCheckStats[i].status.indexOf("generated") == -1 && this.progressCheckStats[i].studentid !== "") {
                            isselectedcorrectitem = 0;
                        }
                    }
                }
            }
            if (selectedItems.length === 0) {
                this.setState({ alertData: { status: 'empty', className: 'warning', message: 'There is no selected items' } });
                window.scrollTo(0, 0);
            }
            else if (isselectedcorrectitem == 0) {
                this.setState({ alertData: { status: 'empty', className: 'warning', message: 'Please select correct items' } });
                window.scrollTo(0, 0);
            }
            else {
                let res = await issueBalance();
                console.log(res);
                if (res >= 3) {
                    this.isInProgressOrIssuedTab = "inprogress";
                    this.setState({ showIssueConfirmModal: true });
                } else {
                    this.setState({ showIssueBalanceModal: true });
                }
            }
        }
        else {
            this.setState({ con: true });
            let res = await issueBalance();
            if (res >= 3) {
                this.is_cohort = true;
                this.isInProgressOrIssuedTab = "inprogress";
                this.setState({ showIssueConfirmModal: true });
            } else {
                this.setState({ showIssueBalanceModal: true });
            }
            this.setState({ con: false });
        }
    }

    closeAlert() {
        var alertData = { status: false, className: '', message: '' };
        this.setState({ alertData: alertData });
    }
    handlePageClick = data => {
        let selected = data.selected;
        this.setState({ currentOffset: selected });
    }

    renderSelectForRevoke() {
        var selectedItems = [];
        if (this.issuedCheckStats.length > 0) {
            for (let i = 0; i < this.issuedCheckStats.length; i++) {
                if (this.issuedCheckStats[i].checked) {
                    let cohortid = this.issuedCheckStats[i].id.split("=")[0];
                    let studentid = this.issuedCheckStats[i].id.split("=")[1];
                    if (cohortid != '' && studentid != '') {
                        selectedItems.push(this.issuedCheckStats[i].id);
                    }
                }
            }
        }
        let Actions = [{ 'label': 'Action ' + selectedItems.length + ' selected', 'value': 'action' }, { 'label': 'Revoke', 'value': 'revoke' }];
        let actionsForIssued = Actions.length > 0
            && Actions.map((item, i) => {
                if (i == 0) {
                    return (
                        <option selected={"selected"} key={i} value={item.value}>{item.label}</option>
                    );
                }
                else {
                    return (
                        <option key={i} value={item.value}>{item.label}</option>
                    );
                }

            }, this);
        //console.log(this.issuedCheckStats);
        if (selectedItems.length > 0) {
            return (
                <select className="col-md-2 cert-row-column" style={{ "height": "30px", "width": "195px" }} onChange={this.selectRevokeForIssued} value={"action"}>
                    {actionsForIssued}
                </select>
            );
        }
        else {
            return (
                <select disabled className="col-md-2 cert-row-column" style={{ "height": "30px", "width": "195px", "opacity": "0.5" }} onChange={this.selectRevokeForIssued} value={"action"}>
                    {actionsForIssued}
                </select>
            );
        }
    }

    setConfirmEmail(pemail) {
        if (this.isconfirmemailfirst == 1 && pemail.length > 1) {
            this.isconfirmemailfirst = 0;
            this.setState({ "confirmemail": "" });
        }
        else {
            this.isconfirmemailfirst = 0;
            this.setState({ "confirmemail": pemail });
        }
    }

    setConfirmPassword(ppass) {
        if (this.isconfirmpassfirst == 1 && ppass.length > 1) {
            this.isconfirmpassfirst = 0;
            this.setState({ "confirmpassword": "" });
        }
        else {
            this.isconfirmpassfirst = 0;
            this.setState({ "confirmpassword": ppass });
        }

    }

    renderSelectForIssuedPageSize() {
        try {
            let selectedItems = 0;
            if (this.issuedCheckStats.length > 0) {
                for (let i = 0; i < this.issuedCheckStats.length; i++) {
                    if (this.issuedCheckStats[i].checked) {
                        let cohortid = this.issuedCheckStats[i].id.split("=")[0];
                        let studentid = this.issuedCheckStats[i].id.split("=")[1];
                        if (cohortid != '' && studentid != '') {
                            selectedItems++
                        }
                    }
                }
            }
            let Actions = [{ 'label': '50', 'value': '50' }, { 'label': '100', 'value': '100' }, { 'label': '150', 'value': '150' }, { 'label': '200', 'value': '200' }, { 'label': '250', 'value': '250' }];
            //let Actions = [{'label' : '50', 'value' : '50'},{'label' : '100', 'value' : '100'},{'label' : '150', 'value' : '150'},{'label' : '200', 'value' : '200'},{'label' : '250', 'value' : '250'},{'label' : '300', 'value' : '300'},{'label' : '350', 'value' : '350'}];
            //let Actions = [{'label' : '50', 'value' : '50'},{'label' : '100', 'value' : '100'}];

            let options = Actions.length > 0
                && Actions.map((item, i) => {
                    if (item.value == this.maxitemsperpage) {
                        return (
                            <option selected={"selected"} key={i} value={item.value}>{item.label}</option>
                        );
                    }
                    else {
                        return (
                            <option key={i} value={item.value}>{item.label}</option>
                        );
                    }

                }, this);


            return (
                <div className="row" style={{ "marginLeft": "0px", "marginTop": "10px" }}>
                    <div >
                        <select style={{ "height": "30px", "width": "65px" }} onChange={this.selectPageSize} >
                            {options}
                        </select>
                    </div>
                    <div style={{ "marginLeft": "10px", "marginTop": "3px" }}>
                        Selected <b>{selectedItems}</b> items
                    </div>
                </div>
            );
        }
        catch (error) {

        }

    }

    renderSelectForProgressPageSize() {
        try {
            let selectedItems = 0;
            if (this.progressCheckStats.length > 0) {
                for (let i = 0; i < this.progressCheckStats.length; i++) {
                    if (this.progressCheckStats[i].checked) {
                        const idarr = this.progressCheckStats[i].id.split("=");
                        if (idarr[1] !== "") {
                            selectedItems++;
                        }
                    }
                }
            }
            let Actions = [{ 'label': '50', 'value': '50' }, { 'label': '100', 'value': '100' }, { 'label': '150', 'value': '150' }, { 'label': '200', 'value': '200' }, { 'label': '250', 'value': '250' }];
            //let Actions = [{'label' : '50', 'value' : '50'},{'label' : '100', 'value' : '100'},{'label' : '150', 'value' : '150'},{'label' : '200', 'value' : '200'},{'label' : '250', 'value' : '250'},{'label' : '300', 'value' : '300'},{'label' : '350', 'value' : '350'}];
            //let Actions = [{'label' : '50', 'value' : '50'},{'label' : '100', 'value' : '100'}];
            let options = Actions.length > 0
                && Actions.map((item, i) => {
                    return (
                        <option key={i} value={item.value}>{item.label}</option>
                    );
                }, this);


            return (
                <div className="row" style={{ "marginLeft": "0px", "marginTop": "10px" }}>
                    <div >
                        <select style={{ "height": "30px", "width": "65px" }} onChange={this.selectPageSize} value={this.maxitemsperpage}>
                            {options}
                        </select>
                    </div>
                    <div style={{ "marginLeft": "10px", "marginTop": "3px" }}>
                        Selected <b>{selectedItems}</b> items
                    </div>
                </div>
            );
        }
        catch (error) {

        }

    }

    setCohort = displayCohort => {
        if (displayCohort.length != 0) {
            this.selectedCohort = displayCohort[0].value;
            this.setState({ size: 1 });
            localStorage.setItem('selectedCohort', this.selectedCohort);
            localStorage.setItem('selectedpageindexforprogress', 0);
            localStorage.setItem('selectedpageindexforissued', 0);
            this.mainPageOffsetIssued = 0;
            this.mainPageOffsetProgress = 0;
            this.initialMainPageIndexIssued = 0;
            this.initialMainPageIndexProgress = 0;
            this.GetCohorts();
        }
    }

    handleDownloadCertificateType(certificatetype, status) {
        if (certificatetype == "json") this.setState({ downloadJson: !status });
        else if (certificatetype == "pdf") this.setState({ downloadPdf: !status });
        else if (certificatetype == "print") this.setState({ downloadPrint: !status });
        else if (certificatetype == "qrcode") this.setState({ downloadQRcode: !status });
    }

    changeHandleMultipleCohort = (e) => {
        this.setState({ size: 1 });
        let idx = e.target.selectedIndex;
        let value = e.target.options[idx].value;
        let selectElementId = e.target.id;
        this.selectedCustomDropdown[`${selectElementId}`] = value;
        localStorage.setItem('selectedCustomDropdown', JSON.stringify(this.selectedCustomDropdown));
        localStorage.setItem('selectedpageindexforprogress', 0);
        localStorage.setItem('selectedpageindexforissued', 0);
        this.mainPageOffsetIssued = 0;
        this.mainPageOffsetProgress = 0;
        this.initialMainPageIndexIssued = 0;
        this.initialMainPageIndexProgress = 0;
        this.GetCohorts();
    }

    getSelectedCohortDropdownValueProgress() {
        var glDropdownCohortArray = this.dropdownCohortArray;
        var result = {};
        Object.keys(glDropdownCohortArray).forEach(function (key, value) {
            if (glDropdownCohortArray[key] == undefined || glDropdownCohortArray[key] == null) glDropdownCohortArray[key] = "";
            if (glDropdownCohortArray == "") {
            }
            else {
                const dropdown = document.getElementById(`${key}`);
                if (dropdown != null) result[key] = dropdown.value;
            }
        });
        return result;
    }

    renderMultiCohortProgress() {
        var glDropdownCohortArray = this.dropdownCohortArray;
        if (Object.keys(glDropdownCohortArray).length === 0 && glDropdownCohortArray.constructor === Object) {
            console.log("JSON Object is empty");
            return;
        } else {
            console.log("JSON Object is not empty");
        }

        const selectStyle = {
            borderColor: 'rgb(206, 212, 218)',
            height: '35px',
            borderRadius: '0.25rem',
            padding: '0px 2px',
            width: '70px'
        };

        var selectedCustomDropdown = localStorage.getItem('selectedCustomDropdown');
        try {
            selectedCustomDropdown = JSON.parse(selectedCustomDropdown);
        } catch (error) {
            selectedCustomDropdown = {};
        }

        const dropdownarray = Object.keys(glDropdownCohortArray).map((key, value) => {
            if (glDropdownCohortArray[key] == undefined || glDropdownCohortArray[key] == null) glDropdownCohortArray[key] = "";
            if (glDropdownCohortArray == "") { }
            else {
                let dropdownOptions = glDropdownCohortArray[key].map((cohortsplitarr, index) => {
                    if (selectedCustomDropdown == null || selectedCustomDropdown[`${key}`] == undefined || selectedCustomDropdown[`${key}`] == null) {
                        if (index == 0) {
                            return (
                                <option selected key={index}>{cohortsplitarr.label}</option>
                            );
                        }
                        else {
                            return (
                                <option key={index}>{cohortsplitarr.label}</option>
                            );
                        }
                    }
                    else {
                        if (selectedCustomDropdown[`${key}`] == cohortsplitarr.label) {
                            return (
                                <option selected key={index}>{cohortsplitarr.label}</option>
                            );
                        }
                        else {
                            return (
                                <option key={index}>{cohortsplitarr.label}</option>
                            );
                        }
                    }
                });
                return (
                    <select style={selectStyle} key={key} id={key} onChange={(e) => this.changeHandleMultipleCohort(e)}>
                        {dropdownOptions}
                    </select>
                );
            }
        });

        const containerStyle = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '10px',
        };

        return (
            <div style={containerStyle}>
                {dropdownarray}
            </div>
        );
    }

    renderMultiCohortIssued() {
        var glDropdownCohortArray = this.dropdownCohortArray;
        if (Object.keys(glDropdownCohortArray).length === 0 && glDropdownCohortArray.constructor === Object) {
            console.log("JSON Object is empty");
            return;
        } else {
            console.log("JSON Object is not empty");
        }

        const selectStyle = {
            borderColor: 'rgb(206, 212, 218)',
            height: '35px',
            borderRadius: '0.25rem',
            padding: '0px 2px',
            width: '70px'
        };

        const dropdownarray = Object.keys(glDropdownCohortArray).map((key, value) => {
            if (glDropdownCohortArray[key] == undefined || glDropdownCohortArray[key] == null) glDropdownCohortArray[key] = "";
            if (glDropdownCohortArray == "") { }
            else {
                let dropdownOptions = glDropdownCohortArray[key].map((cohortsplitarr, index) => (
                    <option key={index}>{cohortsplitarr.label}</option>
                ));
                return (
                    <select style={selectStyle} key={key} id={`${key}_issued`} onChange={() => this.changeHandleMultipleCohort()} >
                        {dropdownOptions}
                    </select>
                );
            }
        });

        const containerStyle = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '10px',
        };

        return (
            <div style={containerStyle}>
                {dropdownarray}
            </div>
        );
    }

    render() {
        let cohortsearchmodelstrg = localStorage.getItem('cohortsearchmode');
        if (cohortsearchmodelstrg == undefined || cohortsearchmodelstrg == null || cohortsearchmodelstrg == "") cohortsearchmodelstrg = 0;
        let selectedpageindexforprogress = localStorage.getItem('selectedpageindexforprogress');
        if (selectedpageindexforprogress == null || selectedpageindexforprogress == undefined) selectedpageindexforprogress = 0;
        this.mainPageOffsetProgress = parseInt(selectedpageindexforprogress);
        this.initialMainPageIndexProgress = parseInt(selectedpageindexforprogress) / parseInt(this.maxitemsperpage);
        let selectedpageindexforissued = localStorage.getItem('selectedpageindexforissued');
        if (selectedpageindexforissued == null || selectedpageindexforissued == undefined) selectedpageindexforissued = 0;
        this.mainPageOffsetIssued = parseInt(selectedpageindexforissued);
        this.initialMainPageIndexIssued = parseInt(selectedpageindexforissued) / parseInt(this.maxitemsperpage);
        var alertData = this.state.alertData;
        var currentOffset = this.state.currentOffset;
        var previewContents = this.state.ModalContents;

        var currentPreview = (previewContents[currentOffset]) ? previewContents[currentOffset].certitem : '';
        var cohortmemberid = (previewContents[currentOffset]) ? previewContents[currentOffset].cohortmemberid : '-1';
        var is_validated = (previewContents[currentOffset]) ? previewContents[currentOffset].is_validated : false;

        let cohortListForProgress = this.Cohorts.length > 0
            && this.Cohorts.map((item, i) => {
                if (this.progressselectindex == i) {
                    return (<option title={item.label} key={i} value={item.value} selected={"selected"}>{item.label}</option>)
                }
                else {
                    return (<option title={item.label} key={i} value={item.value}>{item.label}</option>)
                }
            }, this);


        let cohortListForIssued = this.Cohorts.length > 0
            && this.Cohorts.map((item, i) => {
                if (this.issuedselectindex == i) {
                    return (<option title={item.label} key={i} value={item.value} selected={"selected"}>{item.label}</option>)
                }
                else {
                    return (<option title={item.label} key={i} value={item.value}>{item.label}</option>)
                }
            }, this);

        let certtypeList = this.CertType.length > 0
            && this.CertType.map((item, i) => {
                if (this.certtypeselectindex == i) {
                    return (<option key={i} value={item.value} selected={"selected"}>{item.label}</option>)
                }
                else {
                    return (<option key={i} value={item.value}>{item.label}</option>)
                }
            }, this);

        let downloadtypeList = this.DownloadType.length > 0
            && this.DownloadType.map((item, i) => {
                if (this.downloadtypeselectindex == i) {
                    return (<option key={i} value={item.value} selected={"selected"}>{item.label}</option>)
                }
                else {
                    return (<option key={i} value={item.value}>{item.label}</option>)
                }
            }, this);

        let certstatusList = this.CertStatus.length > 0
            && this.CertStatus.map((item, i) => {
                if (this.certstatusselectindex == i) {
                    return (<option key={i} value={item.value} selected={"selected"}>{item.label}</option>)
                }
                else {
                    return (<option key={i} value={item.value}>{item.label}</option>)
                }
            }, this);

        return (
            <div>
                <section id="tabs" style={{ "padding": "10% 25px", "width": "100%" }} className="project-tab">
                    <div className="row" style={{ marginBottom: '15px' }}>
                        <div className="col-xl-5 col-lg-5 col-md-12">
                            <div style={{ "marginTop": "10px", "display": "flex", "columnGap": "10px" }}>
                                <label style={{ "marginTop": "8px", "fontSize": "14px" }} >Batch</label>
                                {cohortsearchmodelstrg == 0 ? (
                                    <Select className="cohortDropdown" style={{ 'borderColor': 'rgb(206, 212, 218)', 'borderRadius': '4px', 'width': '400px' }} values={this.state.displayCohort} options={this.Cohorts} clearable={true} placeholder="" onChange={values => this.setCohort(values)} />) : this.renderMultiCohortProgress()}
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12">
                            <div style={{ "marginTop": "10px", "display": "flex", "columnGap": "10px" }}>
                                <label style={{ "marginTop": "7px", "fontSize": "14px" }} >Certificate-Type</label>
                                {/*<Select className="col-md-6 cert-row-column"  options={this.Cohorts} onChange={(values) => this.changeCohorts(values)} />*/}
                                <select style={{ "padding": "7px 10px", "borderColor": "rgb(206, 212, 218)", "marginTop": "1px", "borderRadius": "4px", "display": "inline", "marginLeft": "8px", "fontSize": "12px" }} onChange={this.changeCertType} >
                                    {certtypeList}
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-12">
                            <div style={{ "marginTop": "10px", "display": "flex", "columnGap": "10px" }}>
                                <label style={{ "marginTop": "7px", "fontSize": "14px" }} >Certificate Status</label>
                                {/*<Select className="col-md-6 cert-row-column"  options={this.Cohorts} onChange={(values) => this.changeCohorts(values)} />*/}
                                <select style={{ "padding": "7px 10px", "borderColor": "rgb(206, 212, 218)", "marginTop": "1px", "borderRadius": "4px", "display": "inline", "marginLeft": "8px", "fontSize": "12px" }} onChange={this.changeCertStatus} >
                                    {certstatusList}
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-12">
                            <div style={{ "marginTop": "10px" }}>
                                <table border="0" >
                                    <tbody>
                                        <tr>
                                            <td >
                                                <input style={{ "marginTop": "-1px", "background": "white", "height": "38px", "border": "1px solid rgb(206, 212, 218)", "borderBottomLeftRadius": ".25rem", "borderTopLeftRadius": ".25rem", "borderTopRightRadius": "0rem", "borderBottomRightRadius": "0rem", "textAlign": "left", "paddingLeft": "5px", "width": "100%" }} type="text" name="searchinput" id="searchinput"
                                                    onChange={e => this.setState({ searchValue: e.target.value })} onKeyDown={e => this.checkenterkeyforsearch(e)} defaultValue={this.state.searchValue} />
                                            </td>
                                            <td style={{ "textAlign": "left" }}>
                                                <button style={{ "marginTop": "-6px", "borderBottomLeftRadius": "0rem", "borderTopLeftRadius": "0rem", "height": "38px" }} className="input-group-text" onClick={() => this.searchAction()}><i className="fa fa-search"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {this.state.con && (<Spinner></Spinner>)}
                    {(alertData.status) ? (<Alert variant={alertData.className} onClose={() => this.closeAlert()} dismissible>{alertData.message}</Alert>) : ('')}
                    <Tabs>
                        <div label="In-Progress">
                            {(this.state.ProgressStudentsData.data && this.state.ProgressStudentsData.data.length > 0) ?
                                (<TreeTable className="demo-tree-table"
                                    height="360"
                                    headerHeight="40"
                                    value={this.state.ProgressStudentsData}
                                    onChange={this.handleOnChangeProgress}
                                >
                                    <TreeTable.Column renderCell={this.renderIndexCellinprogress} renderHeaderCell={() => <span>Name</span>} basis="350px" grow="0" />
                                    <TreeTable.Column renderCell={this.renderColumninprogress1} renderHeaderCell={() => <span>eMail</span>} />
                                    <TreeTable.Column renderCell={this.renderColumninprogress3} renderHeaderCell={() => <span>PublicKey</span>} />
                                    <TreeTable.Column renderCell={this.renderColumninprogress8} renderHeaderCell={() => <span>SecondEmail</span>} />
                                    <TreeTable.Column renderCell={this.renderColumninprogress4} renderHeaderCell={() => <span>OneTimeCode</span>} />
                                    <TreeTable.Column renderCell={this.renderColumninprogress5} renderHeaderCell={() => <span>Status</span>} />
                                    <TreeTable.Column renderCell={this.renderColumninprogress7} renderHeaderCell={() => <span>Validated</span>} />
                                    <TreeTable.Column renderCell={this.renderColumninprogress9} renderHeaderCell={() => <span style={{ "fontSize": "14px" }}>Photo</span>} />
                                </TreeTable>) : (<div style={{ "marginLeft": "0px" }} className="row"><p>There is no data</p></div>)}
                            {this.renderSelectForProgressPageSize()}
                            <center>
                                <div style={{ "marginTop": "10px" }}>
                                    {this.selectedCertType == "openbadges" ? (<button className="btn btn-info" onClick={() => this.bakeOpenBadges()}>Bake OpenBadges</button>) : ""}
                                    {this.selectedCertType != "openbadges" ? (<button className="btn btn-info" onClick={() => this.generateCertificate()}>Generate Certificate</button>) : ""}
                                    {"     "}
                                    {this.selectedCertType != "openbadges" ? (<div style={{ display: '-webkit-inline-box' }}><button className="btn btn-info" onClick={() => this.previewCertificate()} style={{ borderRadius: '0.25rem 0rem 0rem 0.25rem', borderRightColor: 'white' }}>Preview Certificate</button><button className="btn btn-info" onClick={async () => await this.directDownload()} style={{ borderRadius: '0rem 0.25rem 0.25rem 0rem', height: '38px' }}><i className="fa fa-download"></i></button></div>) : ""}
                                    {"     "}
                                    {this.selectedCertType != "openbadges" ? (<button className="btn btn-info" onClick={() => this.issueCertificate()}>Issue Certificate</button>) : ""}
                                    {"     "}
                                    {this.selectedCertType != "openbadges" ? (<button className="btn btn-info" onClick={() => this.onValidatef("-1")}>Validate for Cohort</button>) : ""}
                                    {"     "}
                                    {this.selectedCertType != "openbadges" ? (<button className="btn btn-info" onClick={() => this.ResetCertificate()}>Reset</button>) : ""}
                                    {"     "}
                                    {this.selectedCertType != "openbadges" ? (<button className="btn btn-info" onClick={() => this.sendCertificateforReview()}>Send for Review</button>) : ""}
                                    <div style={{ "float": "right" }}>
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={this.mainPageCountProgress}
                                            // initialPage={this.initialMainPageIndexProgress}
                                            forcePage={this.initialMainPageIndexProgress}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={(e) => this.handleMainPageClick(e)}
                                            disableInitialCallback={true}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </div>
                                </div>
                            </center>
                            <div id="previewImage" style={{ position: 'absolute', top: '-1000000px', display: 'none' }}></div>
                            <Modal size='lg' dialogClassName="previewEmail" show={this.state.showModal} onHide={this.closeModal} centered scrollable>
                                <Modal.Header closeButton>
                                    <Modal.Title>Preview Certificates</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ 'overflowY': 'auto', 'maxHeight': 'calc(100vh - 210px)' }}>
                                    <Markup content={currentPreview} />
                                </Modal.Body>
                                <Modal.Footer>
                                    <table border="0" style={{ "width": "100%", "margin": "auto" }}>
                                        <tbody>
                                            <tr>
                                                <td colSpan={2} style={{ "textAlign": "center" }}>
                                                    <ReactPaginate
                                                        previousLabel={'previous'}
                                                        nextLabel={'next'}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={this.pageCount}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={this.handlePageClick}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ "width": "50%", "textAlign": "center" }}>
                                                    {(this.state.is_validatepermission) ? ((!is_validated) ? (<Button onClick={() => this.onValidatef(cohortmemberid)}>Validate</Button>) : (<Button disabled="true" variant="success">Validated</Button>)) : ""}
                                                </td>
                                                <td style={{ "width": "50%", "textAlign": "center" }}>
                                                    <Button onClick={this.closeModal}>Close</Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Modal.Footer>
                            </Modal>

                            <Modal size='lt' dialogClassName="issueConfirm" show={this.state.showIssueConfirmModal} onHide={this.closeIssueConfirmModal} centered scrollable>
                                <Modal.Header closeButton>
                                    <Modal.Title><p style={{ "color": "#0275d8", "fontSize": "24px" }}>Alert</p></Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ 'overflowY': 'auto', 'maxHeight': 'calc(100vh - 210px)' }}>
                                    <p style={{ "color": "black", "fontSize": "16px" }}>
                                        This action is irreversible. Once you click the Issue button, the certificate will be issued on blockchain. Please enter your userid and password to proceed ahead.
                                    </p>
                                    <table border="0" style={{ "width": "90%", "margin": "auto" }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p style={{ "color": "black", "fontSize": "16px" }}>EMail:</p>
                                                </td>
                                                <td>
                                                    <input type="text" onChange={e => this.setConfirmEmail(e.target.value)} style={{ "border": "1px solid #ccc", "width": "100%", "height": "35px", "textAlign": "left", "padding": "10px" }} id="emailid" value={this.state.confirmemail} />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <p style={{ "color": "black", "fontSize": "16px" }}>Password:</p>
                                                </td>
                                                <td>
                                                    <input type="password" onChange={e => this.setConfirmPassword(e.target.value)} style={{ "border": "1px solid #ccc", "width": "100%", "height": "35px", "textAlign": "left", "padding": "10px" }} id="passwordid" value={this.state.confirmpassword} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <span><Button onClick={this.issueConfirm}>&nbsp;&nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp;&nbsp;</Button></span>
                                    <Button onClick={this.closeIssueConfirmModal}>Cancel</Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal size='lt' dialogClassName="issueConfirm" show={this.state.showIssueBalanceModal} onHide={this.closeIssueBalanceModal} centered scrollable>
                                <Modal.Header closeButton>
                                    <Modal.Title><p style={{ "color": "#0275d8", "fontSize": "24px" }}>TOP UP</p></Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ 'overflowY': 'auto', 'maxHeight': 'calc(100vh - 210px)' }}>
                                    <p style={{ "color": "black", "fontSize": "16px" }}>
                                        You have insufficient balance to issue.
                                        Send a request to the CertOnce team to Top Up.
                                    </p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={this.closeIssueBalanceModal}>Cancel</Button>
                                    <Button onClick={this.issueBalanceConfirm}>&nbsp;&nbsp;Send&nbsp;&nbsp;</Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal size='lt' dialogClassName="previewphoto" show={this.state.showPhotoModal} onHide={this.closeModal} centered scrollable>
                                <Modal.Header closeButton>
                                    <Modal.Title>{this.state.selectedPhotoTitle}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ 'overflowY': 'auto', 'maxHeight': 'calc(100vh - 210px)' }}>
                                    <Markup content={this.state.selectedphotoContent} />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={this.closeModal}>Close</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        <div label="Issued" >
                            {(this.state.IssuedStudentsData.data && this.state.IssuedStudentsData.data.length > 0) ?
                                (<TreeTable className="demo-tree-table"
                                    height="360"
                                    headerHeight="40"
                                    value={this.state.IssuedStudentsData}
                                    onChange={this.handleOnChangeIssued}
                                >
                                    <TreeTable.Column renderCell={this.renderIndexCellinIssued} renderHeaderCell={() => <span>Name</span>} basis="350px" grow="0" />
                                    <TreeTable.Column renderCell={this.renderColumninprogress1} renderHeaderCell={() => <span>eMail</span>} />
                                    <TreeTable.Column renderCell={this.renderColumninprogress3} renderHeaderCell={() => <span>PublicKey</span>} />
                                    <TreeTable.Column renderCell={this.renderColumninprogress8} renderHeaderCell={() => <span>SecondEmail</span>} />
                                    <TreeTable.Column renderCell={this.renderColumninprogress4} renderHeaderCell={() => <span>OneTimeCode</span>} />
                                    <TreeTable.Column renderCell={this.renderColumninprogress5} renderHeaderCell={() => <span>Status</span>} />
                                    <TreeTable.Column renderCell={this.renderColumninprogress6} renderHeaderCell={() => <span>Preview/Pdf/Json/Print/QR</span>} />
                                </TreeTable>) : (<div style={{ "marginLeft": "0px" }} className="row"><p>There is no data</p></div>)}
                            {this.renderSelectForIssuedPageSize()}
                            <center>
                                <div style={{ "marginTop": "10px" }}>
                                    {this.selectedCertType == "openbadges" ? (<button className="btn btn-info" onClick={() => this.sendOpenBadges()}>Send OpenBadges</button>) : ""}
                                    {this.selectedCertType != "openbadges" ? (<button className="btn btn-info" onClick={() => this.sendCertificate()}>Send Certificate</button>) : ""}
                                    {"     "}
                                    {this.selectedCertType != "openbadges" ? (<button className="btn btn-info" onClick={() => this.downloadConfirm()}>Download</button>) : ""}
                                    {"     "}
                                    {this.selectedCertType != "openbadges" ? (<button className="btn btn-info" onClick={() => this.selectRevokeForIssued()}>Revoke</button>) : ""}
                                    {"     "}
                                    {this.selectedCertType != "openbadges" ? (<button className="btn btn-info" onClick={() => this.UnholdStudents()}>Unhold</button>) : ""}
                                    {"     "}
                                    {<button className="btn btn-info" onClick={() => this.refresh()}>Refresh</button>}
                                    <div style={{ "float": "right" }}>
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={this.mainPageCountIssued}
                                            // initialPage={this.initialMainPageIndexIssued}
                                            forcePage={this.initialMainPageIndexIssued}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={(e) => this.handleMainPageIssuedClick(e)}
                                            disableInitialCallback={true}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </div>
                                </div>
                            </center>

                            <Modal size='lg' dialogClassName="previewEmail" show={this.state.showModal} onHide={this.closeModal} centered scrollable>
                                <Modal.Header closeButton>
                                    <Modal.Title>Preview Certificates</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ 'overflowY': 'auto', 'maxHeight': 'calc(100vh - 210px)' }}>
                                    <Markup content={currentPreview} />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={this.closeModal}>Close</Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal size='lt' dialogClassName="issueConfirm" show={this.state.showRevokeConfirmModal} onHide={this.closeRevokeConfirmModal} centered scrollable>
                                <Modal.Header closeButton>
                                    <Modal.Title><p style={{ "color": "#0275d8", "fontSize": "24px" }}>Alert</p></Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ 'overflowY': 'auto', 'maxHeight': 'calc(100vh - 210px)' }}>
                                    <p style={{ "color": "black", "fontSize": "16px" }}>
                                        This action is irreversible. Once you click the Revoke button, the certificate will be revoked by issuer. Please enter your userid, password and revoke reason to proceed ahead.
                                    </p>
                                    <table border="0" style={{ "width": "90%", "margin": "auto" }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p style={{ "color": "black", "fontSize": "16px" }}>EMail:</p>
                                                </td>
                                                <td>
                                                    <input type="text" onChange={e => this.setConfirmEmail(e.target.value)} style={{ "border": "1px solid #ccc", "width": "100%", "height": "35px", "textAlign": "left", "padding": "10px" }} id="emailid" value={this.state.confirmemail} />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <p style={{ "color": "black", "fontSize": "16px" }}>Password:</p>
                                                </td>
                                                <td>
                                                    <input type="password" onChange={e => this.setConfirmPassword(e.target.value)} style={{ "border": "1px solid #ccc", "width": "100%", "height": "35px", "textAlign": "left", "padding": "10px" }} id="passwordid" value={this.state.confirmpassword} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p style={{ "color": "black", "fontSize": "16px" }}>Reason:</p>
                                                </td>
                                                <td>
                                                    <input type="text" style={{ "border": "1px solid #ccc", "width": "100%", "height": "35px", "textAlign": "left", "padding": "10px" }} id="revokereason" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <span><Button onClick={this.issueConfirm}>&nbsp;&nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp;&nbsp;</Button></span>
                                    <Button onClick={this.closeRevokeConfirmModal}>Cancel</Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal size='lt' dialogClassName="downloadEmail" show={this.state.showDownloadEmailModal} onHide={this.closeDownloadEmailModal} centered scrollable>
                                <Modal.Header closeButton>
                                    <Modal.Title><p style={{ "color": "#0275d8", "fontSize": "24px" }}>Download Options</p></Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ 'overflowY': 'auto', 'maxHeight': 'calc(100vh - 210px)' }}>
                                    <table border="0" style={{ "width": "90%", "margin": "auto" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '40%' }}><p style={{ "color": "black", "fontSize": "16px" }}>Download Type:</p></td>
                                                <td style={{ width: '60%' }}>
                                                    <select style={{ "padding": "7px 10px", "borderColor": "rgb(206, 212, 218)", "marginTop": "1px", "borderRadius": "4px", "display": "inline", "marginLeft": "8px", "fontSize": "12px" }} onChange={this.changeDownloadType}>
                                                        {downloadtypeList}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '40%', paddingTop: '25px' }}>
                                                    <p style={{ "color": "black", "fontSize": "16px" }}>Certificate Type:</p>
                                                </td>
                                                <td style={{ width: '60%', paddingTop: '25px', paddingLeft: '10px' }}>
                                                    <label>
                                                        <input type="checkbox" checked={this.state.downloadJson} onChange={e => this.handleDownloadCertificateType("json", this.state.downloadJson)}></input>
                                                        &nbsp;&nbsp; JSON
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '40%' }}></td>
                                                <td style={{ width: '60%', paddingLeft: '10px' }}>
                                                    <label>
                                                        <input type="checkbox" checked={this.state.downloadPdf} onChange={e => this.handleDownloadCertificateType("pdf", this.state.downloadPdf)}></input>
                                                        &nbsp;&nbsp;PDF
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '40%' }}></td>
                                                <td style={{ width: '60%', paddingLeft: '10px' }}>
                                                    <label>
                                                        <input type="checkbox" checked={this.state.downloadPrint} onChange={e => this.handleDownloadCertificateType("print", this.state.downloadPrint)}></input>
                                                        &nbsp;&nbsp;PRINT
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '40%' }}></td>
                                                <td style={{ width: '60%', paddingLeft: '10px' }}>
                                                    <label>
                                                        <input type="checkbox" checked={this.state.downloadQRcode} onChange={e => this.handleDownloadCertificateType("qrcode", this.state.downloadQRcode)}></input>
                                                        &nbsp;&nbsp;QRCODDE
                                                    </label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <span><Button onClick={this.download}>&nbsp;&nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp;&nbsp;</Button></span>
                                    <Button onClick={this.closeDownloadEmailModal}>Cancel</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </Tabs>
                </section>
            </div>
        )
    }

    handleOnChangeProgress = (newValue) => {
        this.setState({ ProgressStudentsData: newValue });
    }

    handleOnChangeIssued = (newValue) => {
        this.setState({ IssuedStudentsData: newValue });
    }

    renderIndexCellinprogress = (row) => {
        var checkStats = this.progressCheckStats;
        let checked = false;
        let idval = row.data.cohortid + "=" + row.data.studentid;
        let parentidval = row.data.cohortid + "=";
        let parentchecked = false;
        for (let i = 0; i < checkStats.length; i++) {
            const id = checkStats[i].id;
            if (id == parentidval) {
                if (checkStats[i].checked == true) {
                    parentchecked = true;
                }
                break;
            }
        }
        if (parentchecked == false) {
            for (let i = 0; i < checkStats.length; i++) {
                const childid = checkStats[i].id;
                if (childid == idval) {
                    if (checkStats[i].checked == true) {
                        checked = true;
                    }
                    break;
                }
            }
        }
        else {
            checked = true;
        }
        return (
            <div style={{ paddingLeft: (row.metadata.depth * 15) + 'px', "height": "40px" }} >
                <span className="toggle-button-wrapper" >
                    {(row.metadata.hasChildren) ? (<span style={{ "fontSize": "13px", "height": "19px" }} className="toggle-button" onClick={row.toggleChildren}></span>)
                        : ''}
                </span>
                {(row.data.is_child == true)
                    ? ((row.data.field11 == "krutidev010")
                        ? (<span><span style={{ "verticalAlign": "top", "lineHeight": "31px" }}><input type="checkbox" style={{ "width": "30px", "height": "17px" }} checked={checked} onChange={(e) => this.toggleCheckboxChangeChildren(e, row.data.cohortid, row.data.studentid, 'progress')} /></span><span style={{ "fontSize": "14px", "color": "#040402", "verticalAlign": "super", "lineHeight": "26px", "wordBreak": "break-all", "fontFamily": "krutidev010regular" }}><span>{row.data.field1} {row.data.field2} {row.data.field3}</span></span></span>)
                        : (<span><span style={{ "verticalAlign": "top", "lineHeight": "31px" }}><input type="checkbox" style={{ "width": "30px", "height": "17px" }} checked={checked} onChange={(e) => this.toggleCheckboxChangeChildren(e, row.data.cohortid, row.data.studentid, 'progress')} /></span><span style={{ "fontSize": "14px", "color": "#040402", "verticalAlign": "super", "lineHeight": "26px", "wordBreak": "break-all" }}><span>{row.data.field1} {row.data.field2} {row.data.field3}</span></span></span>)
                    ) : (
                        <span><span style={{ "verticalAlign": "top", "lineHeight": "36px" }}><input type="checkbox" style={{ "width": "30px", "height": "17px" }} checked={checked} onChange={(e) => this.toggleCheckboxChangeParent(e, row.data.cohortid, row.data.studentid, 'progress')} /></span><span style={{ "fontSize": "10px", "color": "#040402" }}><span>{row.data.field1} {row.data.field2} {row.data.field3}</span></span></span>
                    )}
            </div>
        );
    }

    renderIndexCellinIssued = (row) => {
        var checkStats = this.issuedCheckStats;
        let checked = false;
        let idval = row.data.cohortid + "=" + row.data.studentid;
        let parentidval = row.data.cohortid + "=";
        let parentchecked = false;
        for (let i = 0; i < checkStats.length; i++) {
            const id = checkStats[i].id;
            if (id == parentidval) {
                if (checkStats[i].checked == true) {
                    parentchecked = true;
                }
                break;
            }
        }

        if (parentchecked == false) {
            for (let i = 0; i < checkStats.length; i++) {
                const childid = checkStats[i].id;
                if (childid == idval) {
                    if (checkStats[i].checked == true) {
                        checked = true;
                    }
                    break;
                }
            }
        }
        else {
            checked = true;
        }

        return (
            <div style={{ paddingLeft: (row.metadata.depth * 15) + 'px', "height": "40px" }} >
                <span className="toggle-button-wrapper" >
                    {(row.metadata.hasChildren)
                        ? (<span style={{ "fontSize": "13px", "height": "19px" }} className="toggle-button" onClick={row.toggleChildren}></span>)
                        : ''
                    }
                </span>
                {(row.data.is_child == true) ? ((row.data.field11 == "krutidev010")
                    ? (<span><span style={{ "verticalAlign": "top", "lineHeight": "31px" }}><input type="checkbox" style={{ "width": "30px", "height": "17px" }} checked={checked} onChange={(e) => this.toggleCheckboxChangeChildren(e, row.data.cohortid, row.data.studentid, 'issued')} /></span><span style={{ "fontSize": "14px", "color": "#040402", "verticalAlign": "super", "lineHeight": "26px", "wordBreak": "break-all", "fontFamily": "krutidev010regular" }}><span>{row.data.field1} {row.data.field2} {row.data.field3}</span></span></span>)
                    : (<span><span style={{ "verticalAlign": "top", "lineHeight": "31px" }}><input type="checkbox" style={{ "width": "30px", "height": "17px" }} checked={checked} onChange={(e) => this.toggleCheckboxChangeChildren(e, row.data.cohortid, row.data.studentid, 'issued')} /></span><span style={{ "fontSize": "14px", "color": "#040402", "verticalAlign": "super", "lineHeight": "26px", "wordBreak": "break-all" }}><span>{row.data.field1} {row.data.field2} {row.data.field3}</span></span></span>))
                    : (<span><span style={{ "verticalAlign": "top", "lineHeight": "36px" }}><input type="checkbox" style={{ "width": "30px", "height": "17px" }} checked={checked} onChange={(e) => this.toggleCheckboxChangeParent(e, row.data.cohortid, row.data.studentid, 'issued')} /></span><span style={{ "fontSize": "10px", "color": "#040402" }}><span>{row.data.field1} </span></span></span>)}
            </div>
        );
    }

    renderColumninprogress1 = (row) => {
        return (<span style={{ "fontSize": "14px", "wordBreak": "break-all", "height": "40px" }} >{row.data.field4}</span>);
    }

    renderColumninprogress2 = (row) => {
        return (<span style={{ "fontSize": "14px", "wordBreak": "break-all", "height": "40px" }} >{row.data.field5}</span>);
    }

    renderColumninprogress3 = (row) => {
        return (<span style={{ "fontSize": "14px", "wordBreak": "break-all", "height": "40px" }} >{row.data.field6}</span>);
    }

    renderColumninprogress4 = (row) => {
        return (<span style={{ "fontSize": "14px", "wordBreak": "break-all", "height": "40px" }} >{row.data.field7}</span>);
    }

    renderColumninprogress5 = (row) => {
        return (<span style={{ "fontSize": "14px", "wordBreak": "break-all", "height": "40px" }} >{row.data.status}</span>);
    }

    renderColumninprogress7 = (row) => {
        var is_validated = "No";
        if (row.data.is_validated == true) is_validated = "Yes";
        if (row.data.is_child == false) is_validated = "";
        return (<span style={{ "fontSize": "14px", "wordBreak": "break-all", "height": "40px" }} >{is_validated}</span>);
    }

    renderColumninprogress8 = (row) => {
        return (
            <span style={{ "fontSize": "14px", "wordBreak": "break-all", "height": "40px" }} >{row.data.secondemailaddress}</span>
        );
    }

    renderColumninprogress6 = (row) => {
        if (row.data.status !== "") {
            return (
                <div style={{ "marginBottom": "18px" }}>
                    <span style={{ "verticalAlign": "text-top" }} onClick={(e) => this.previewCertificateForIssued(row.data.cohortid, row.data.studentid, row.data.status)}>
                        <img src="https://www.certonce.com/images/preview.png" style={{ "height": "20px" }} ></img></span>{" "}
                    <a href={row.data.pdfdownloadurl} target="_blank" rel="noopener noreferrer" download>
                        <img src="https://www.certonce.com/images/pdf.png" style={{ "height": "20px" }}></img></a>{" "}
                    <a href={row.data.jsondownloadurl} target="_blank" rel="noopener noreferrer" download>
                        <img src="https://www.certonce.com/images/json.png" style={{ "height": "20px" }}></img></a>{" "}
                    {(row.data.printpdfurl != "") ? <a href={row.data.printpdfurl} target="_blank" rel="noopener noreferrer" download>
                        <img src="https://www.certonce.com/images/printicon.jpg" style={{ "height": "20px" }}></img></a> : ''}{" "}
                    {(row.data.qrcodeurl != "") ? <a href={row.data.qrcodeurl} target="_blank" rel="noopener noreferrer" download>
                        <img src="https://test.certonce.com/images/qrcode.png" style={{ "height": "20px" }}></img></a> : ''}
                </div>
            );
        }
    }

    renderColumninprogress9 = (row) => {
        if (row.data.field10 == "Yes")
            return (<div style={{ "marginBottom": "18px" }}>
                <span style={{ "fontSize": "14px", "wordBreak": "break-all", "height": "40px" }} >{row.data.field10}</span>{" "}
                <span style={{ "fontSize": "14px", "wordBreak": "break-all", "height": "40px" }} onClick={(e) => this.previewStudentPhoto(row.data.cohortid, row.data.studentid)}>
                    <img src="https://www.certonce.com/images/preview.png" style={{ "height": "20px" }} ></img></span>
            </div>);
        else if (row.data.field10 == "No")
            return (<span style={{ "fontSize": "14px", "wordBreak": "break-all", "height": "40px" }} >{row.data.field10}</span>);
    }

    toggleCheckboxChangeChildren = (e, cohortid, studentid, type) => {
        let checkStats = [];
        let idval = cohortid + "=" + studentid;
        if (type == 'progress') {
            checkStats = this.progressCheckStats;
        } else if (type == 'issued') {
            checkStats = this.issuedCheckStats;
        }

        for (let i = 0; i < checkStats.length; i++) {
            const id = checkStats[i].id;
            if (id == idval) {
                if (e.target.checked == true) {
                    checkStats[i].checked = true;
                }
                else {
                    checkStats[i].checked = false;
                    let parentidval = cohortid + "=";
                    for (let ii = 0; ii < checkStats.length; ii++) {
                        const id = checkStats[ii].id;

                        if (id == parentidval) {
                            checkStats[ii].checked = false;
                        }
                    }
                }
                break;
            }
        }
        let checkchageval = this.state.checkchange;
        this.setState({ checkchange: !checkchageval });
    }

    toggleCheckboxChangeParent = (e, cohortid, studentid, type) => {
        let checkStats = [];
        if (type == 'progress') {
            checkStats = this.progressCheckStats;
        } else if (type == 'issued') {
            checkStats = this.issuedCheckStats;
        }
        let idval = cohortid + "=" + studentid;
        for (let i = 0; i < checkStats.length; i++) {
            const id = checkStats[i].id;
            const idarr = id.split("=");
            if (e.target.checked == true) {
                checkStats[i].checked = true;
            }
            else {
                checkStats[i].checked = false;
            }
        }
        let checkchageval = this.state.checkchange;
        this.setState({ checkchange: !checkchageval });
    }
}

function mapStateToProps(state, actions) {
    if (state.fetchStudentsData && state.fetchStudentsData.Data && state.fetchStudentsData.Data.Status) {
        return { Students: state.fetchStudentsData.Data }
    }
    else if (state.RevokeCertificate && state.RevokeCertificate.Data && state.RevokeCertificate.Data.Status) {
        return { RevokeStatus: state.RevokeCertificate.Data }
    }
    else if (state.SendCertificate && state.SendCertificate.Data && state.SendCertificate.Data.Status) {
        return { SendStatus: state.SendCertificate.Data }
    }
    else if (state.SendCertificateforReview && state.SendCertificateforReview.Data && state.SendCertificateforReview.Data.Status) {
        return { SendforReviewStatus: state.SendCertificateforReview.Data }
    }
    else if (state.GenerateCertificate && state.GenerateCertificate.Data && state.GenerateCertificate.Data.Status) {
        return { GenerateStatus: state.GenerateCertificate.Data }
    }
    else if (state.BakeOpenBadges && state.BakeOpenBadges.Data && state.BakeOpenBadges.Data.Status) {
        return { BakeStatus: state.BakeOpenBadges.Data }
    }
    else if (state.BatchProcess && state.BatchProcess.Data && state.BatchProcess.Data.Status) {
        return { BatchStatus: state.BatchProcess.Data }
    }
    else if (state.IssueCertificate && state.IssueCertificate.Data && state.IssueCertificate.Data.Status) {
        return { IssueStatus: state.IssueCertificate.Data }
    }
    else if (state.IssueConfirm && state.IssueConfirm.Data && state.IssueConfirm.Data.Status) {
        return { IssueConfirm: state.IssueConfirm.Data }
    }
    else if (state.ValidateReducer && state.ValidateReducer.Data && state.ValidateReducer.Data.Status) {
        return { ValidateStatus: state.ValidateReducer.Data }
    }
    else if (state.PreviewCertificate && state.PreviewCertificate.Data && state.PreviewCertificate.Data.Status) {
        return { PreviewCertificateStatus: state.PreviewCertificate.Data }
    }
    else if (state.fetchProfileData && state.fetchProfileData.Data && state.fetchProfileData.Data.Status) {
        return { Profile: state.fetchProfileData.Data }
    }
    else if (state.ResetCertificate && state.ResetCertificate.Data && state.ResetCertificate.Data.Status) {
        return { ResetCertificateStatus: state.ResetCertificate.Data }
    }
    else if (state.UnholdStudents && state.UnholdStudents.Data && state.UnholdStudents.Data.Status) {
        return { UnholdStudentsStatus: state.UnholdStudents.Data }
    }
    else if (state.PreviewStudentPhoto && state.PreviewStudentPhoto.Data && state.PreviewStudentPhoto.Data.Status) {
        return { PreviewStudentPhotoStatus: state.PreviewStudentPhoto.Data }
    }
    else if (state.DownloadPreviewImage && state.DownloadPreviewImage.Data && state.DownloadPreviewImage.Data.Status) {
        return { DownloadPreviewImageStatus: state.DownloadPreviewImage.Data }
    }
    else if (state.fetchCohortsData && state.fetchCohortsData.Data && state.fetchCohortsData.Data.Status) {
        return { Cohorts: state.fetchCohortsData.Data }
    }
    else {
        return {};
    }
}

export default connect(mapStateToProps)(withRouter(ManageCertificateComponent));