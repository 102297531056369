export const verifier_ip = "https://verify.certonce.com/";

/*
var server = "https://www.certonce.com:5000/";
export const server_ip="https://www.certonce.com";
//*/

//*
var server = "https://test.certonce.com:5000/";
export const server_ip="https://test.certonce.com";
//*/

/*
var server = "https://test.certonce.com:5000/";
export const server_ip = "http://localhost:3000";
//*/


export const imagebaseurl = server_ip + "/img/";

export const USER_LOGIN = server + "api/login";

export const USER_REGISTER = server + "api/signup";

export const GET_PLANS = server + "api/GetPlans";

export const stripecharge = server + "api/stripecharge";
export const getProfile = server + "api/getProfile";
export const updateProfile = server + "api/updateProfile";
export const getTransaction = server + "api/getTransaction";
export const forget = server + "api/forgetpwd";
export const CHANGEPASSWORD = server + "api/changepassword";
export const UPLOADSTUDENTS = server + "api/uploadstuduents";
export const getStudents = server + "api/getstudents";
export const getStudentsForStep = server + "api/getstudentsforstep";
export const getCohorts = server + "api/getcohorts";
export const sendInvitation = server + "api/sendinvitation";
export const generatecertificate = server + "api/generatecertificate";
export const issueBalanceUrl = server + "api/issuebalance";
export const issueBalanceConfirm = server + "api/issuebalanceconfirm";
export const issuecertificate = server + "api/issuecertificate";
export const previewcertificate = server + "api/previewcertificate";
export const insertReceipientForGS = server + "api/insertReceipientForGS";
export const sendinvitationGS = server + "api/sendinvitationGS";
export const getpublickey = server + "api/getpublickey";
export const issueurl = server + "api/issueGS";
export const sendcertificateGS = server + "api/sendcertificateGS";
export const sendscheduledemoGS = server + "api/sendscheduledemoGS";
export const revokeCertificate = server + "api/revokeCertificate";
export const sendCertificate = server + "api/sendCertificate";
export const issuecertificatedaemon = server + "api/issuecertificatedaemon";
export const generateaddress = server + "api/generateAddress";
export const updatecertificatetemplateurl = server + "api/updatecertificatetemplate";
export const fetchcertificatetemplateurl = server + "api/fetchcertificatetemplate";
export const uploadlogoimageurl = server + "api/uploadlogoimage";
export const issueconfirm = server + "api/issueconfirm";
export const updatevalidate = server + "api/updatevalidate";
export const UPLOADPHOTOS = server + "api/uploadphotos";
export const UPLOADMULTIPHOTOS = server + "api/uploadmultiphotos";

export const registerteamuserurl = server + "api/registerteamuser";
export const updatepermission = server + "api/updatepermission";

export const bakeopenbadgesurl = server + "api/bakeopenbadges";
export const copyemailaspkurl = server + "api/copyemailaspk";
export const batchprocess = server + "api/batchprocess";

export const updateinformationurl = server + "api/updateinformation";
export const getlogoimageurl = server + "api/getlogoimage";
export const getsignatureimageurl = server + "api/getsignatureimage";
export const getPartners = server + "api/getPartners";
export const dashboard = server + "api/dashboard";


export const resetcertificateURL = server + "api/resetcertificate";
export const unholdStudents = server + "api/unhold";
export const generatepreviewcertificate = server + "api/generatePreviewCertificate";
export const bakepreviewopenbadges = server + "api/bakepreviewopenbadges";

export const previewstudentphoto = server + "api/previewstudentphoto";
export const deletestudent = server + "api/deletestudent";
export const downloadPreviewImage = server + "api/downloadPreviewImage";

export const uploadstudentbyapi = server + "api/uploadstudentbyapi";
export const getbatchurlbyapi = server + "api/getbatchurlbyapi";

export const getStudentsInputData = server + "api/getstudentsinputdata";
export const updateStudentsInputData = server + "api/updatestudentsinputdata";
export const sendCertificateforReviewurl = server + "api/sendcertificateforreview";