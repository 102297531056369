
import axios from 'axios';
import { issueBalanceUrl } from "./constants";
console.log("issueBalanceUrl", issueBalanceUrl);

export const issueBalance = async (isTopUp = false) => {
    let Token = 'bearer ' + localStorage.getItem('Token');
    let userId = localStorage.getItem('user_id');
    let data = { userId: userId };
    if (isTopUp) {
        data = { userId: userId, isTopUp: true };
    }
    try {
        let res = await axios({
            method: "POST",
            url: issueBalanceUrl,
            data: data,
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        });
        if (res.data && res.data.balance) {
            return res.data.balance;
        }
    } catch (err) {
        console.log("issueBalance err = : ", err);

    }
    return 0;
}